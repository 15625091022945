import { SessionState, createInitialSession } from "lib/session";
import { createContext, useCallback, useContext, useState } from "react";

export type ConciergeContext = {
  state: SessionState;
  update(state: Partial<SessionState>): void;
  set(state: SessionState): void;
  reset(): void;
};

export const Context = createContext<ConciergeContext | null>(null);

interface ConciergeSessionContextProps {
  children: React.ReactNode;
}

export function ConciergeSessionContext({
  children,
}: ConciergeSessionContextProps) {
  const [state, setState] = useState<SessionState>(createInitialSession);

  const update = useCallback((input: Partial<SessionState>) => {
    setState((state) => {
      return {
        ...state,
        ...input,
      };
    });
  }, []);

  const reset = useCallback(() => {
    setState(createInitialSession);
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        update,
        reset,
        set: setState,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useConciergeSessionContext() {
  const config = useContext(Context);
  if (!config) {
    throw new Error("useConcierge without ConciergeContext");
  }
  return config;
}
