import { Question } from "@cur8/questionnaire";
import { PropsWithChildren } from "react";
import { Typography } from "render/ui/layout/Typography";
import styles from "./styles.module.sass";

interface QuestionGroupProps {
  question: Question<unknown, React.ReactNode>;
}

export function QuestionGroup({
  question,
  children,
}: PropsWithChildren<QuestionGroupProps>) {
  return (
    <div className={styles.QuestionGroup}>
      <Typography variant="description" weight="light">
        {question.statement}
      </Typography>
      {children}
    </div>
  );
}
