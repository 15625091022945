import { useQuery } from "@tanstack/react-query";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (documentName: string | undefined) => [
  "legal-document",
  documentName,
];
const queryFn = (documentUrl: string | undefined) => async () => {
  if (typeof documentUrl === "undefined") {
    Promise.reject(new Error("Invalid documentUrl"));
  }
  const cacheBreak = Math.random() * 1000;
  const response = await fetch(`${documentUrl}?q=${cacheBreak}`, {
    cache: "no-store",
  });
  return response.text();
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useLegalDocumentQuery<T = Body>(
  documentUrl: string | undefined,
  options: QueryOptions<Body, Key, T> = {}
) {
  return useQuery({
    queryKey: queryKey(documentUrl),
    queryFn: queryFn(documentUrl),
    ...options,
  });
}
