import { PropsWithChildren, createElement, useMemo } from "react";
import styles from "./styles.module.sass";

type ListType = "ordered" | "unordered";
type ListProps = PropsWithChildren<{ type: ListType }>;

export function List({ type, children }: ListProps) {
  const tag = useMemo(() => {
    const map = new Map<ListType, string>([
      ["ordered", "ol"],
      ["unordered", "ul"],
    ]);
    return map.get(type);
  }, [type]);

  if (tag == null) {
    throw new Error(`Prop [${type}] is not supported for list type`);
  }

  return createElement(tag, { className: styles.List, children });
}
