import { Patient, fromAPI } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function usePatient() {
  const api = useAPIClient();

  const { logError } = useReporting();

  const fetchPatient = useCallback(
    (patientId: string) => {
      return api.patient
        .fetchPatient({ patientId })
        .result.then(fromAPI.toPatientDetails)
        .catch(logError);
    },
    [logError, api]
  );

  const updatePreferredName = useCallback(
    async (patientId: string, preferredName: string) => {
      const patient = await api.patient
        .fetchPatient({ patientId })
        .result.then(fromAPI.toPatientDetails)
        .catch(logError);

      const name: Patient["name"] = patient.name ?? {};
      name.displayName = preferredName;

      return api.patient
        .updatePatient(patientId, {
          name,
        })
        .result.then(fromAPI.toPatientDetails)
        .catch(logError);
    },
    [logError, api]
  );

  return { fetchPatient, updatePreferredName };
}
