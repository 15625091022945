import { PropsWithChildren, createElement, useMemo } from "react";
import styles from "./styles.module.sass";

interface TypographyProps {
  variant:
    | "large-title"
    | "medium-title"
    | "small-title"
    | "paragraph"
    | "sub-title"
    | "description"
    | "small"
    | "paragraph-sub";
  weight?: Weight;
  component?: "p" | "h1" | "h2" | "h3" | "div" | "span";
}

type Weight = "normal" | "bold" | "light";

const VariantToTag: Record<TypographyProps["variant"], string> = {
  "large-title": "h1",
  "medium-title": "h2",
  "small-title": "h3",
  paragraph: "p",
  "sub-title": "p",
  description: "p",
  small: "p",
  "paragraph-sub": "p",
};

export function Typography({
  variant,
  weight,
  children,
  component,
}: PropsWithChildren<TypographyProps>) {
  const tag = component ?? VariantToTag[variant];

  if (!tag) {
    throw new Error(`Unknown variant: ${variant}`);
  }

  const element = useMemo(() => {
    return createElement(tag, {
      className: styles.typography,
      children,
      "data-weight": weight,
      "data-variant": variant,
    });
  }, [tag, children, weight, variant]);

  return element;
}
