import { fromAPI } from "@cur8/rich-entity";
import { useFinishCheckInMutation } from "hooks/mutations/useFinishCheckInMutation";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Typography } from "render/ui/layout/Typography/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";

interface AssignToRoomProps {
  goTo(url: string): void;
}

export function AssignToRoomView({ goTo }: AssignToRoomProps) {
  const {
    state: { patient, visit },
  } = useConciergeSessionContext();

  const finishCheckIn = useFinishCheckInMutation();
  const { update } = useConciergeSessionContext();

  if (!patient) {
    return <BailView title="No Member" />;
  }

  if (!visit) {
    return <BailView title="No Visit object" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">
              Are you in the scan room?
            </Typography>
            <Typography variant="description">
              When {patient.name?.displayName || "the member"} is ready to start
              the scan, assign them to the room.
            </Typography>
          </>
        }
        buttons={
          <ActionsFooter
            right={
              <ActionButton
                variant="primary"
                busy={finishCheckIn.isPending}
                onClick={async () => {
                  if (finishCheckIn.isPending || visit.checkinEndTimestamp)
                    return;
                  const v = await finishCheckIn.mutateAsync(visit);
                  update({ visit: fromAPI.toVisit(v) });
                  goTo(paths.checkInSuccess.url({}));
                }}
              >
                Assign to room
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
