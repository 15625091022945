import { Visit, fromAPI } from "@cur8/rich-entity";
import { record, string } from "@pomle/shapes";
import { APIClient } from "lib/api/client";
import { Persister } from "lib/persist/persist";
import { SessionState } from "lib/session";

type AppState = {
  visitId: string;
  patientId: string;
};

const validate = {
  appState: record<AppState>({
    visitId: string(""),
    patientId: string(""),
  }),
};

const LOCAL_STORAGE_KEY = "session/v2";

function clearState() {
  console.debug("Clearing state", LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function storeState(visit: Visit) {
  const state: AppState = {
    visitId: visit.visitId,
    patientId: visit.patientId,
  };
  console.debug("Storing state", state);
  const text = JSON.stringify(state);
  window.localStorage.setItem(LOCAL_STORAGE_KEY, text);
}

function retrieveState() {
  const text = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!text) {
    return;
  }
  const payload = JSON.parse(text);
  console.debug("Retrieving state", payload);
  const state = validate.appState(payload);
  if (state.patientId && state.visitId) {
    return state;
  }
  console.debug("Bad state", state);
  clearState();
}

async function restoreSession(
  api: APIClient,
  state: AppState
): Promise<SessionState> {
  const session: SessionState = {};

  const visit = await api.visit
    .fetchVisit({ visitId: state.visitId, patientId: state.patientId })
    .result.then(fromAPI.toVisit);

  session.visit = visit;

  session.patient = await api.patient
    .fetchPatient({ patientId: visit.patientId })
    .result.then(fromAPI.toPatientDetails);

  if (visit.roomId) {
    session.room = await api.schedule
      .fetchRoom({ roomId: visit.roomId })
      .result.then(fromAPI.toRoom);
  }

  session.slot = await api.booking
    .getSlot({ slotId: visit.slotId })
    .result.then(fromAPI.toSlot);

  return session;
}

export class LocalStoragePersister implements Persister {
  api: APIClient;

  constructor(api: APIClient) {
    this.api = api;
  }

  async wakeup() {
    const state = retrieveState();
    if (state) {
      const session = await restoreSession(this.api, state);
      return session;
    }
    return null;
  }

  update(state: Partial<SessionState>): void {
    if (state.visit) {
      storeState(state.visit);
    } else {
      clearState();
    }
  }
}
