import { baseApiUrlResolver } from "@cur8/config";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useAppInsights } from "./AppInsightsContext";
import { useConfig } from "./ConfigContext";
import { useMSAL } from "./MSALContext";
import { SilentRequest } from "@azure/msal-browser";
import { APIClient, createClient } from "lib/api/client";

type APIContextState = {
  client?: APIClient;
};

const INITIAL: APIContextState = {
  client: undefined,
};

export const Context = createContext<APIContextState>(INITIAL);

interface APIContextProps {
  children: React.ReactNode;
}

export function APIContext({ children }: APIContextProps) {
  const config = useConfig();
  const { auth, instance } = useMSAL();
  const appInsights = useAppInsights();

  const getToken = useCallback(() => {
    const redirectURL = new URL(window.location.href);
    redirectURL.pathname = "/silent-auth.html";

    const request: SilentRequest = {
      account: auth?.account ?? undefined,
      scopes: config.msal.scopes.token,
      redirectUri: redirectURL.toString(),
    };

    return instance.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    });
  }, [auth, instance, config]);

  const onError = useCallback(
    (error: any) => {
      appInsights.trackException(error);
    },
    [appInsights]
  );

  const api = useMemo(() => {
    return {
      client: createClient({
        buildURL: baseApiUrlResolver(config),
        getToken,
        onError,
      }),
    };
  }, [config, getToken, onError]);

  return <Context.Provider value={api}>{children}</Context.Provider>;
}

export function useAPIContext() {
  return useContext(Context);
}

export function useAPIClient() {
  const { client } = useAPIContext();
  if (!client) {
    throw new Error("API Client not initialized");
  }
  return client;
}
