import { APITypes } from "@cur8/api-client";

export const DevicePMID: { [key in APITypes.DeviceName]?: string } = {
  [APITypes.DeviceName.SkinSystem]: "100355",
  [APITypes.DeviceName.TissueSystem]: "100515",
  [APITypes.DeviceName.CardioLasers]: "100596",
  [APITypes.DeviceName.CardioRadar]: "100596",
};

export const DeviceTitle: { [key in APITypes.DeviceName]: string } = {
  [APITypes.DeviceName.SkinSystem]: "Skin system",
  [APITypes.DeviceName.TissueSystem]: "Tissue system",
  [APITypes.DeviceName.CardioLasers]: "Cardio lasers",
  [APITypes.DeviceName.CardioRadar]: "Cardio radar",
  [APITypes.DeviceName.BloodSystem]: "Blood system",
  [APITypes.DeviceName.DermaApp]: "Derma app",
  [APITypes.DeviceName.DocUI]: "DocUI",
  [APITypes.DeviceName.Ecg]: "ECG",
  [APITypes.DeviceName.Scale]: "Scale",
  [APITypes.DeviceName.Pox]: "POX",
  [APITypes.DeviceName.MesiSystem]: "MESI system",
  [APITypes.DeviceName.GripStrength]: "Grip strength",
  [APITypes.DeviceName.EyePressure]: "Eye pressure",
  [APITypes.DeviceName.CardioSystem]: "Cardio system",
};

export interface AdverseEventReport {
  severity: APITypes.Severity;
  deviceRelated: APITypes.RelatedProbability;
  examProcedureRelated: APITypes.RelatedProbability;
  actionTaken: APITypes.AdverseEventActionTaken;
  outcome: APITypes.AdverseEventOutcome;
  sentToAuthorities: boolean;
}

export interface DeviceDeficiency {
  device: {
    deviceName: APITypes.DeviceName;
    id?: string | null;
  };
  description: string;
  possibleReason: APITypes.PossibleReason;
  issueCategory: APITypes.IssueCategory;
  actionTaken: {
    action: APITypes.IncidentReportAction;
    other?: string | null;
  };
  ledToHealthRisk: boolean;
  sentToAuthorities: boolean;
}

export interface IncidentReport {
  deviceDeficiencies: DeviceDeficiency[];
  adverseEventReport?: AdverseEventReport;
  visitId: string;
  patientId: string;
  eTag: string | null;
}
