import styles from "./styles.module.sass";

interface DropdownItemProps extends React.ButtonHTMLAttributes<any> {
  icon?: React.ReactNode;
}

export function DropdownItem({ children, icon, ...props }: DropdownItemProps) {
  return (
    <button
      className={styles.DropdownItem}
      type="button"
      {...props}
      data-clickabe={!!props.onClickCapture || !!props.onClick}
    >
      {children} {icon}
    </button>
  );
}
