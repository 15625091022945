import { APITypesV1 } from "@cur8/api-client";
import { Slot, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";

export function fetchSlot(api: APIClient, slotId: string) {
  return api.booking.getSlot({ slotId }).result.then(fromAPI.toSlot);
}

function isCancelled(visit: APITypesV1.Visit) {
  return visit.status === APITypesV1.VisitStatus.Cancelled;
}

export function fetchPatientVisitCandidates(
  api: APIClient,
  patientId: string,
  origin: DateTime
) {
  const range = {
    start: origin.startOf("day"),
    end: origin.endOf("day"),
  };

  const startTime = range.start.toISO();
  const endTime = range.end.toISO();
  if (!startTime || !endTime) {
    throw new Error("Invalid Dates");
  }

  return api.visit
    .getPatientVisits(
      { patientId },
      {
        pageSize: 20,
        startDateTimeOffsetRange: {
          start: startTime,
          end: endTime,
        },
      }
    )
    .result.then((data) => {
      return data.items
        .filter((visit) => !isCancelled(visit))
        .map(fromAPI.toVisit);
    });
}

//const TOO_LATE_CHECKIN_MAX_DIFF_IN_MINUTES = 20;
//const TOO_EARLY_CHECKIN_MAX_DIFF_IN_MINUTES = 20;
const LATE_CHECKIN_MAX_DIFF_IN_MINUTES = 10;
const EARLY_CHECKIN_MAX_DIFF_IN_MINUTES = 10;

export enum SlotStatus {
  Unknown = "unknown",
  TooEarly = "too_early",
  TooLate = "too_late",
  Early = "early",
  Late = "late",
  Valid = "valid",
}

export function validateSlotWithinBounds(slot: Slot): SlotStatus {
  const slotStart = slot.startTime;
  const diff = slotStart.diffNow("minutes").minutes;

  if (diff < -LATE_CHECKIN_MAX_DIFF_IN_MINUTES) {
    return SlotStatus.Late;
  }

  if (diff > EARLY_CHECKIN_MAX_DIFF_IN_MINUTES) {
    return SlotStatus.Early;
  }

  return SlotStatus.Valid;
}
