import { localize, Locale } from "lib/i18n/localization";

const Title = localize({
  [Locale.enGB]: (
    <>Ask the member if they consent to us reading their medical journal</>
  ),
  [Locale.svSE]: <></>,
});

const Description = localize({
  [Locale.enGB]: (
    <>
      We need this to ensure high quality medical care. The member can revoke
      the consent using the member app.
    </>
  ),
  [Locale.svSE]: <></>,
});

const Expiry = localize({
  [Locale.enGB]: <>The consent expires automatically after 1 year.</>,
  [Locale.svSE]: <></>,
});

const Skip = localize({
  [Locale.enGB]: <>Skip</>,
  [Locale.svSE]: <></>,
});

const Consent = localize({
  [Locale.enGB]: <>Consent</>,
  [Locale.svSE]: <></>,
});

const Continue = localize({
  [Locale.enGB]: <>Continue</>,
  [Locale.svSE]: <></>,
});

export const Trans = { Title, Description, Expiry, Skip, Consent, Continue };
