import { APIFetchError } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useAPIClient } from "render/context/APIContext";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { Poll } from "render/hooks/effects";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography";
import { Radio } from "render/ui/presentation/Radio";
import { ActionButton } from "render/ui/trigger/ActionButton";
import type { AuthMethod } from "render/views/JoinSessionView/token";
import { BailView } from "../BailView/BailView";
import { ReactComponent as NekoLogoText } from "./assets/neko-logo-text.svg";
import { ReactComponent as QRCodeIcon } from "./assets/show-qrcode.svg";
import { ReactComponent as WelcomeToText } from "./assets/welcome-to.svg";
import { useQRCodeURL } from "./hooks/useQRCodeURL";
import styles from "./styles.module.sass";

class SessionGoneError extends Error {}

const FAKE_QR_ENTROPY = "0129841902481=24g9182418=4gk190gk190gj8m";

const POLL_BACKOFF_TIME = 2000;

function createSeed() {
  return (Math.random() * Number.MAX_SAFE_INTEGER).toFixed();
}

interface JoinSessionViewProps {
  goTo(url: string): void;
}

export function JoinSessionView({ goTo }: JoinSessionViewProps) {
  const { state, update } = useConciergeSessionContext();

  const { session } = state;

  const api = useAPIClient();

  const [error, setError] = useState<Error>();

  const { logError } = useReporting();

  const goToNext = useCallback(() => {
    const url = paths.assignSlot.url({});
    goTo(url);
  }, [goTo]);

  const readPatientFromSession = useCallback(
    async (sessionId: string) => {
      const session = await api.checkinSession
        .fetchSession({ sessionId })
        .result.then(fromAPI.toCheckinSessionState)
        .catch((error: unknown) => {
          if (error instanceof APIFetchError) {
            if (error.response.status === 404) {
              throw new SessionGoneError(error.message);
            }
          }

          throw error;
        });

      if (!session.patientId) {
        return null;
      }

      const patient = await api.patient
        .fetchPatient({ patientId: session.patientId })
        .result.then(fromAPI.toPatientDetails);

      return { patient, session };
    },
    [api]
  );

  const patient = state.patient;

  useEffect(() => {
    if (patient) {
      return;
    }

    if (!session) {
      return;
    }

    return Poll.run(async () => {
      try {
        const response = await readPatientFromSession(session.sessionId);
        if (response) {
          const { patient, session } = response;
          update({ patient, session });
          goToNext();
          return Poll.STOP;
        }
      } catch (error: unknown) {
        if (error instanceof SessionGoneError) {
          setError(error);
          return Poll.STOP;
        } else {
          throw error;
        }
      }
    }, POLL_BACKOFF_TIME);
  }, [patient, session, readPatientFromSession, update, goToNext]);

  const startCheckIn = useCallback(async () => {
    const seed = createSeed();

    await api.checkinSession
      .createSession({ seed })
      .result.then(fromAPI.toCheckinSessionState)
      .then((session) => {
        update({ session });
      })
      .catch(logError);
  }, [api.checkinSession, logError, update]);

  const [authMethod, setAuthMethod] = useState<AuthMethod>("bankid");
  const patientDeviceURL = useQRCodeURL(authMethod);

  if (error) {
    if (error instanceof SessionGoneError) {
      return <BailView message="Session gone" />;
    }

    return <BailView message={error.message} />;
  }

  return (
    <PageFrameContent>
      <div className={styles.JoinSessionView}>
        <div className={styles.intro}>
          <div className={styles.welcome}>
            <WelcomeToText />
          </div>
          <div className={styles.paddingContainer}>
            <div className={styles.logo}>
              <NekoLogoText />
            </div>
            <div className={styles.disclaimer}>
              <Typography variant="description">
                Please point your phone camera at the QR code to check-in.
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.qrCode} data-url={patientDeviceURL}>
            <div
              className={styles.blur}
              data-visible={!session}
              onClick={startCheckIn}
            >
              <div className={styles.showcta}>
                <QRCodeIcon height={40} width={40} />
                <Typography variant="paragraph" weight="bold" component="span">
                  Show code
                </Typography>
              </div>
            </div>
            <div className={styles.frame}>
              <QRCode value={patientDeviceURL ?? FAKE_QR_ENTROPY} size={320} />
            </div>
            <form
              className={styles.form}
              hidden={!session}
              onChange={(event) => {
                const { elements } = event.currentTarget;
                const el = elements.namedItem("phoneauth") as RadioNodeList;
                setAuthMethod(el.value as AuthMethod);
              }}
            >
              {authMethods.map(({ id, description }) => (
                <Radio
                  key={id}
                  name="phoneauth"
                  value={id}
                  defaultChecked={id === authMethod}
                >
                  {description}
                </Radio>
              ))}
            </form>
          </div>
          {patient && (
            <div className={styles.cta}>
              <ActionButton onClick={goToNext}>Continue</ActionButton>
            </div>
          )}
        </div>
      </div>
    </PageFrameContent>
  );
}

const authMethods: { id: AuthMethod; description: string }[] = [
  { id: "bankid", description: "BankID" },
  { id: "phoneauth", description: "Phone authentication" },
];
