import styles from "./styles.module.sass";

type RadioProps = Omit<JSX.IntrinsicElements["input"], "type">;

export function Radio({ children, ...props }: RadioProps) {
  return (
    <label className={styles.Radio}>
      <input {...props} type="radio" />
      <div className={styles.checkboxIcon} />
      <span className={styles.label}>{children}</span>
    </label>
  );
}
