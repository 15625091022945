import { codecs, createPath } from "@pomle/paths";

const root = createPath("/", {});

const checkIn = root.append("/check-in", {});

const assignSlot = checkIn.append("/assign-slot", {});

const awaitingConsent = checkIn.append("/awaiting-consent", {});

const counterSign = checkIn.append("/counter-sign-consent", {});

const medicalJournalConsent = checkIn.append("/medical-journal-consent", {});

const preferredName = checkIn.append("/preferred-name", {});

const questionnaire = checkIn.append("/onboarding/questionnaire/quiz", {});

const questionnaireStep = questionnaire.append("/step/:stepNo", {
  stepNo: codecs.number,
});

const riskAssessment = checkIn.append("/risk-assessment", {});

const immediateRiskAssessment = checkIn.append(
  "/immediate-risk-assessment",
  {}
);

const assignToRoom = checkIn.append("/assign-to-room", {});

const checkInSuccess = checkIn.append("/success", {});

const checkOut = root.append("/checkout", {});

const incidentReport = root.append("/incident-report/:activeStep", {
  activeStep: codecs.number,
});

export const paths = {
  assignToRoom,
  checkInSuccess,
  questionnaire,
  questionnaireStep,
  root,
  awaitingConsent,
  counterSign,
  medicalJournalConsent,
  assignSlot,
  preferredName,
  riskAssessment,
  immediateRiskAssessment,
  checkOut,
  incidentReport,
};
