import { Answer, Question } from "@cur8/questionnaire";
import React from "react";
import { StepView } from "render/views/IncidentReportView/components/StepView";

interface QuestionViewProps {
  question: Question<unknown, React.ReactNode>;
  answer?: Answer;
  optional?: boolean;
  onPrev?: () => void;
  onNext: () => void;
  children: React.ReactNode;
  cta?: React.ReactElement;
  description?: React.ReactNode;
  verticalCentered?: boolean;
  navMode?: "sticky-footer" | "end-of-content";
  extraTopPadding?: boolean;
}

export function QuestionView({
  question,
  children,
  cta,
  description,
  onPrev,
  verticalCentered = false,
  extraTopPadding = false,
  navMode = "sticky-footer",
}: QuestionViewProps) {
  return (
    <StepView
      caption={question.statement}
      subCaption={description}
      onPrev={onPrev}
      navMode={navMode}
      cta={cta}
      verticalCentered={verticalCentered}
      extraTopPadding={extraTopPadding}
    >
      {children}
    </StepView>
  );
}
