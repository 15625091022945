import React from "react";
import style from "./styles.module.sass";

interface HoverTextButtonProps extends React.ButtonHTMLAttributes<any> {
  active?: boolean;
}

export function HoverTextButton({
  children,
  active = false,
  ...props
}: HoverTextButtonProps) {
  return (
    <button
      className={style.HoverTextButton}
      type="button"
      data-active={active}
      {...props}
    >
      {children}
    </button>
  );
}
