import { TokenV1, createToken, encodeToken } from "@cur8/frango";

export function createSessionToken(sessionId: string) {
  return createToken(sessionId);
}

export function createPatientDeviceURL(
  token: TokenV1,
  baseURL: string,
  authMethod: AuthMethod
) {
  const obfuscatedToken = encodeToken(token);
  const path = ["concierge", "check-in", obfuscatedToken]
    .map(encodeURIComponent)
    .join("/");
  const url = new URL(baseURL + "/" + path);
  url.search = `?authmethod=${authMethod}`;
  return url.toString();
}

export type AuthMethod = "bankid" | "phoneauth";
