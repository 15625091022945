import { Typography } from "render/ui/layout/Typography";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";

export function MemberJoinedView() {
  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">Member joined session</Typography>
            <Typography variant="description">Verifying booking...</Typography>
          </>
        }
      />
    </PageFrameContent>
  );
}
