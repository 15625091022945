import {
  Answer,
  ChoiceQuestion,
  ChoiceResponse,
  createChoiceAnswer,
  isChoiceResponse,
} from "@cur8/questionnaire";
import React, { useCallback } from "react";
import { Typography } from "render/ui/layout/Typography/Typography";
import styles from "./styles.module.sass";

interface RadioChoiceInputProps<T> {
  question: ChoiceQuestion<T, React.ReactNode>;
  answer?: Answer;
  onAnswer: (answer: Answer<ChoiceResponse>) => void;
  name: string;
}

export function RadioChoiceInput<T>({
  question,
  answer,
  onAnswer,
  name,
}: RadioChoiceInputProps<T>) {
  let pickedIndex: number;

  if (answer && isChoiceResponse(answer.response)) {
    pickedIndex = answer.response.choiceIndex;
  }

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      const answer = createChoiceAnswer(choiceIndex);
      onAnswer(answer);
    },
    [onAnswer]
  );
  return (
    <div className={styles.RadioChoiceInput}>
      {question.choices.map((question, choiceIndex) => {
        const classNames = [styles.choice];
        if (choiceIndex === pickedIndex) {
          classNames.push(styles.picked);
        }

        return (
          <label
            key={choiceIndex}
            className={styles.choice}
            data-checked={choiceIndex === pickedIndex}
          >
            <input
              type="radio"
              name={name}
              onChange={() => handleChoice(choiceIndex)}
              checked={choiceIndex === pickedIndex}
            />
            <div className={styles.radio} />
            <Typography component="span" variant="small-title">
              {question.statement}
            </Typography>
          </label>
        );
      })}
    </div>
  );
}
