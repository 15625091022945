import {
  APIContextOptions,
  createAPIContext,
  createBlobAPI,
  createBookingAPIv2,
  createCheckinSessionAPI,
  createConsentAPIv1,
  createConsentAPIv2,
  createMeasurementsAPI,
  createPatientAPI,
  createQuestionnaireAPI,
  createScanAPI,
  createScheduleAPI,
  createUserAPI,
  createVisitAPI,
  createVisitSummaryAPI,
} from "@cur8/api-client";

export function createClient(options: APIContextOptions) {
  const context = createAPIContext(options);

  return {
    blob: createBlobAPI(context),
    booking: createBookingAPIv2(context),
    checkinSession: createCheckinSessionAPI(context),
    consent: createConsentAPIv1(context),
    consentV2: createConsentAPIv2(context),
    measurement: createMeasurementsAPI(context),
    patient: createPatientAPI(context),
    scan: createScanAPI(context),
    schedule: createScheduleAPI(context),
    user: createUserAPI(context),
    visit: createVisitAPI(context),
    visitSummary: createVisitSummaryAPI(context),
    questionnaire: createQuestionnaireAPI(context),
  };
}

export type APIClient = ReturnType<typeof createClient>;
