import { APITypes } from "@cur8/api-client";
import { APIClient } from "lib/api/client";

type DocumentSigning = {
  required: boolean;
  doc: string;
};

export const Signature = {
  Cardio: {
    doc: "forms/study/2023-cardio.en.html",
    required: true,
  },
  Skin: {
    doc: "forms/study/2023-dermaflow.en.html",
    required: true,
  },
  Tissue: {
    doc: "forms/study/2023-spectrum.en.html",
    required: true,
  },
  AutomaticSummaries: {
    doc: "forms/gdpr/2024-march-summaries",
    required: false,
  },
} as const;

const SIGNATURES: DocumentSigning[] = [
  Signature.Cardio,
  Signature.Skin,
  Signature.Tissue,
  Signature.AutomaticSummaries,
];

export function isSigned(
  sign: DocumentSigning,
  consents: (APITypes.ConsentSummary | APITypes.ConsentSignature)[]
) {
  const consent = findConsent(consents, sign.doc);
  if (!consent) {
    return false;
  }
  if (consent.expired) {
    return false;
  }
  if (sign.required && consent.optedOut) {
    return false;
  }
  return true;
}

export function hasSignedAll(
  consents: (APITypes.ConsentSummary | APITypes.ConsentSignature)[]
) {
  return SIGNATURES.every((signature) => {
    return isSigned(signature, consents);
  });
}

function normalizeURI(
  consent: APITypes.ConsentSummary | APITypes.ConsentSignature
) {
  return "documentUri" in consent ? consent.documentUri : consent.documentUrl;
}

export function findConsent(
  consents: (APITypes.ConsentSummary | APITypes.ConsentSignature)[],
  study: string
) {
  return consents.find((con) => {
    return normalizeURI(con).includes(study);
  });
}

function fetchStudiesExhaustively(
  api: APIClient,
  params: APITypes.PatientConsentQueryParams
) {
  function fetchPage(params: APITypes.PatientConsentQueryParams) {
    return api.consent.getListOfConsents({
      ...params,
    }).result;
  }

  async function fetchAll(params: APITypes.PatientConsentQueryParams) {
    const buffer: APITypes.ConsentSummary[] = [];

    let nextPage: string | undefined;
    do {
      const res = await fetchPage({
        ...params,
        continuationToken: nextPage,
      });
      buffer.push(...res.items);
      nextPage = res.nextPage;
    } while (nextPage);

    return buffer;
  }

  return fetchAll(params);
}

export function fetchActiveConsentSummaries(api: APIClient, patientId: string) {
  return fetchStudiesExhaustively(api, {
    patientId,
    validityState: APITypes.ConsentValidityState.WithinValidityRange,
  });
}

export async function fetchActiveConsents(api: APIClient, patientId: string) {
  const summaries = await fetchActiveConsentSummaries(api, patientId);

  const consents = await Promise.all(
    summaries.map((summary) => {
      return fetchConsent(api, patientId, summary.id);
    })
  );

  return consents;
}

export function fetchConsent(
  api: APIClient,
  patientId: string,
  consentId: string
) {
  return api.consent.getConsent({ patientId, consentId }).result;
}
