import styles from "./styles.module.sass";
import React from "react";

interface PageFrameProps {
  head: React.ReactNode;
  children: React.ReactNode;
}

export function PageFrame({ head, children }: PageFrameProps) {
  return (
    <div className={styles.PageFrame}>
      <header>{head}</header>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
