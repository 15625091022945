const STOP = Symbol("poll stop");

function run(fn: () => Promise<unknown> | unknown, waitMs: number) {
  let cancelled = false;

  const poll = async () => {
    if (cancelled) {
      return;
    }

    const signal = await fn();
    if (signal === STOP) {
      return;
    }

    setTimeout(poll, waitMs);
  };

  setTimeout(poll, 0);

  return () => {
    cancelled = true;
  };
}

export const Poll = {
  run,
  STOP,
};
