import { APITypesV2 } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string | undefined) => [
  "questionnaires",
  patientId,
];
const queryFn =
  (apiClient: APIClient, patientId: string | undefined) => async () => {
    if (patientId == null) {
      return Promise.reject("No patientId");
    }
    return apiClient.questionnaire.getQuestionnaireResponses(
      {
        patientId,
      },
      // TODO: we should pass visitId when we start associating questionnaire responses with visits
      {}
    ).result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useQuestionnaireQuery<T = Body>({
  patientId,
  options = {},
}: {
  patientId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId),
    staleTime: Infinity,
    enabled:
      options.enabled != null
        ? Boolean(patientId) && options.enabled
        : Boolean(patientId),
    ...options,
  });
}

export function useIsLatestQuestionnaireCompleteQuery<T = Body>({
  patientId,
  options,
}: {
  patientId: string | undefined;
  options: Omit<QueryOptions<Body, Key, T>, "select">;
}) {
  return useQuestionnaireQuery({
    patientId,
    options: {
      ...options,
      select: (data) => {
        const latestQuestionnaire = data.items.at(0);
        if (latestQuestionnaire == null) {
          return false;
        }
        return (
          latestQuestionnaire.state ===
          APITypesV2.QuestionnaireResponseState.Complete
        );
      },
    },
  });
}

useQuestionnaireQuery.queryKey = queryKey;
useQuestionnaireQuery.queryFn = queryFn;
