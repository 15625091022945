import { PropsWithChildren } from "react";
import { useMSAL } from "./MSALContext";
import { LoginView } from "render/views/LoginView";

export function AuthenticationWall({ children }: PropsWithChildren) {
  const { auth } = useMSAL();

  if (!auth) {
    return <LoginView />;
  }

  return <>{children}</>;
}
