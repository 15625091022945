import { APITypes } from "@cur8/api-client";
import { createDeviceIssueDescriptionStep } from "render/views/IncidentReportView/steps/DeviceIssueDescriptionStep";
import { createDeviceIssueDetailsStep } from "render/views/IncidentReportView/steps/DeviceIssueDetailsStep";

export const EcgIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.Ecg
);
export const CardioLasersIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.CardioLasers
);
export const CardioRadarIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.CardioRadar
);
export const MESIIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.MesiSystem
);

export const EcgIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.Ecg
);
export const CardioLasersIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.CardioLasers
);

export const CardioRadarIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.CardioRadar
);
export const MESIIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.MesiSystem
);

export const BloodSystemIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.BloodSystem
);
export const BloodSystemIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.BloodSystem
);

export const DermaAppIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.DermaApp
);
export const DermaAppIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.DermaApp
);

export const DocUIIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.DocUI
);
export const DocUIIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.DocUI
);

export const EyePressureIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.EyePressure
);
export const EyePressureIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.EyePressure
);

export const GripStrengthIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.GripStrength
);
export const GripStrengthIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.GripStrength
);

export const POXIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.Pox
);
export const POXIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.Pox
);

export const TissueSystemIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.TissueSystem
);
export const TissueSystemIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.TissueSystem
);

export const SkinSystemIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.SkinSystem
);
export const SkinSystemIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.SkinSystem
);

export const ScaleIssueStep = createDeviceIssueDescriptionStep(
  APITypes.DeviceName.Scale
);
export const ScaleIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypes.DeviceName.Scale
);

export { DeviceListStep } from "./DeviceListStep";
export { HasAdverseEventStep } from "./HasAdverseEventsStep";
export { HasDeviceIssueStep } from "./HasDeviceIssueStep";
export { MitigationActionStep } from "./MitigationActionStep";
export { OutcomeStep } from "./OutcomeStep";
export { RelationshipToDeviceStep } from "./RelationshipToDeviceStep";
export { RelationshipToProcedureStep } from "./RelationshipToProcedureStep";
export { SeverityGradeStep } from "./SeverityGradeStep";
export { SuccessStep } from "./SuccessStep";

export function getDeviceDeficiencyStepsForDevice(device: APITypes.DeviceName) {
  switch (device) {
    case APITypes.DeviceName.Ecg:
      return [EcgIssueStep, EcgIssueDetailsStep];
    case APITypes.DeviceName.CardioLasers:
      return [CardioLasersIssueStep, CardioLasersIssueDetailsStep];
    case APITypes.DeviceName.CardioRadar:
      return [CardioRadarIssueStep, CardioRadarIssueDetailsStep];
    case APITypes.DeviceName.MesiSystem:
      return [MESIIssueStep, MESIIssueDetailsStep];
    case APITypes.DeviceName.BloodSystem:
      return [BloodSystemIssueStep, BloodSystemIssueDetailsStep];
    case APITypes.DeviceName.DermaApp:
      return [DermaAppIssueStep, DermaAppIssueDetailsStep];
    case APITypes.DeviceName.DocUI:
      return [DocUIIssueStep, DocUIIssueDetailsStep];
    case APITypes.DeviceName.EyePressure:
      return [EyePressureIssueStep, EyePressureIssueDetailsStep];
    case APITypes.DeviceName.GripStrength:
      return [GripStrengthIssueStep, GripStrengthIssueDetailsStep];
    case APITypes.DeviceName.Pox:
      return [POXIssueStep, POXIssueDetailsStep];
    case APITypes.DeviceName.TissueSystem:
      return [TissueSystemIssueStep, TissueSystemIssueDetailsStep];
    case APITypes.DeviceName.SkinSystem:
      return [SkinSystemIssueStep, SkinSystemIssueDetailsStep];
    case APITypes.DeviceName.Scale:
      return [ScaleIssueStep, ScaleIssueDetailsStep];
    default:
      throw new Error(`Unknown device ${device}`);
  }
}
