import { APITypes } from "@cur8/api-client";
import { Answers } from "@cur8/questionnaire";
import {
  DeviceList,
  HasAdverseEvent,
  HasDeviceIssue,
  MitigationAction,
  RelationshipToDevice,
  RelationshipToProcedure,
  SeverityGrade,
  WhatWasTheOutcome,
} from "render/views/IncidentReportView/questions";
import { DeviceDescriptionQuestionMap } from "render/views/IncidentReportView/steps/DeviceIssueDescriptionStep";
import {
  DevicCategoryOfTheIssueQuestionMap,
  DevicReasonOfTheIssueQuestionMap,
  DevicWhatWasDoneQuestionQuestionMap,
  DeviceCouldLeadToHealthIssuesQuestionQuestionMap,
  DeviceWhatWasDoneOtherDetailsQuestionMap,
} from "render/views/IncidentReportView/steps/DeviceIssueDetailsStep";
import {
  AdverseEventReport,
  DeviceDeficiency,
  DevicePMID,
  IncidentReport,
} from "render/views/IncidentReportView/types";

export const questionnaireToReport = (
  answers: Answers,
  visitId: string,
  patientId: string
): IncidentReport => {
  const report: IncidentReport = {
    deviceDeficiencies: [],
    visitId: visitId,
    patientId,
    eTag: null,
  };

  const hasDeviceIssues = answers.lookup(HasDeviceIssue);
  if (hasDeviceIssues) {
    const deviceList = answers.lookup(DeviceList);
    if (deviceList) {
      for (const device of deviceList) {
        const descriptionQuestion = DeviceDescriptionQuestionMap[device];
        const description = answers.lookup(descriptionQuestion);

        const devicePMID = DevicePMID[device];

        if (!description) throw new Error(`Missing description for ${device}`);

        const possibleReasonQuestion = DevicReasonOfTheIssueQuestionMap[device];
        const possibleReason = answers.lookup(possibleReasonQuestion);

        if (!possibleReason)
          throw new Error(`Missing possible reason for ${device}`);

        const categoryQuestion = DevicCategoryOfTheIssueQuestionMap[device];
        const issueCategory = answers.lookup(categoryQuestion);

        if (!issueCategory)
          throw new Error(`Missing issue category for ${device}`);

        const actionTakenQuestion = DevicWhatWasDoneQuestionQuestionMap[device];
        const actionTaken = answers.lookup(actionTakenQuestion);

        let actionTakenOther = undefined;
        if (actionTaken === APITypes.IncidentReportAction.Other) {
          const actionTakenOtherQuestion =
            DeviceWhatWasDoneOtherDetailsQuestionMap[device];
          actionTakenOther = answers.lookup(actionTakenOtherQuestion);
        }

        if (!actionTaken) throw new Error(`Missing action taken for ${device}`);

        const ledToHealthRiskQuestion =
          DeviceCouldLeadToHealthIssuesQuestionQuestionMap[device];
        const ledToHealthRisk = answers.lookup(ledToHealthRiskQuestion);

        if (ledToHealthRisk === undefined)
          throw new Error(`Missing health risk for ${device}`);

        const deviceDeficiency: DeviceDeficiency = {
          device: {
            deviceName: device,
            id: devicePMID,
          },
          description,
          possibleReason,
          issueCategory,
          actionTaken: {
            action: actionTaken,
            other: actionTakenOther,
          },
          ledToHealthRisk,
          sentToAuthorities: false,
        };

        report.deviceDeficiencies.push(deviceDeficiency);
      }
    }
  }

  const hasAdverseEvents = answers.lookup(HasAdverseEvent);
  if (hasAdverseEvents) {
    const severity = answers.lookup(SeverityGrade);

    if (!severity) throw new Error("Missing severity");

    const deviceRelated = answers.lookup(RelationshipToDevice);

    if (!deviceRelated) throw new Error("Missing device related answer");

    const actionTaken = answers.lookup(MitigationAction);

    if (!actionTaken) throw new Error("Missing action taken");

    const examProcedureRelated = answers.lookup(RelationshipToProcedure);

    if (!examProcedureRelated)
      throw new Error("Missing exam procedure related answer");

    const outcome = answers.lookup(WhatWasTheOutcome);

    if (!outcome) throw new Error("Missing outcome");

    const adverseEventReport: AdverseEventReport = {
      severity,
      deviceRelated,
      actionTaken,
      examProcedureRelated,
      outcome,
      sentToAuthorities: false,
    };

    report.adverseEventReport = adverseEventReport;
  }

  return report;
};
