import { APITypes } from "@cur8/api-client";
import { TextQuestion } from "@cur8/questionnaire";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput/TextInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import {
  BloodSystemIssueDescription,
  CardioLasersIssueDescription,
  CardioRadarIssueDescription,
  DermaAppIssueDescription,
  DocUIIssueDescription,
  ECGIssueDescription,
  EyePressureIssueDescription,
  GripStrengthIssueDescription,
  MESIIssueDescription,
  POXIssueDescription,
  ScaleIssueDescription,
  SkinSystemIssueDescription,
  TissueSystemIssueDescription,
} from "render/views/IncidentReportView/questions";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";

export const DeviceDescriptionQuestionMap: Record<
  APITypes.DeviceName,
  TextQuestion<string>
> = {
  [APITypes.DeviceName.CardioLasers]: CardioLasersIssueDescription,
  [APITypes.DeviceName.CardioRadar]: CardioRadarIssueDescription,
  [APITypes.DeviceName.CardioSystem]: CardioRadarIssueDescription,
  [APITypes.DeviceName.Ecg]: ECGIssueDescription,
  [APITypes.DeviceName.MesiSystem]: MESIIssueDescription,
  [APITypes.DeviceName.BloodSystem]: BloodSystemIssueDescription,
  [APITypes.DeviceName.DermaApp]: DermaAppIssueDescription,
  [APITypes.DeviceName.DocUI]: DocUIIssueDescription,
  [APITypes.DeviceName.EyePressure]: EyePressureIssueDescription,
  [APITypes.DeviceName.GripStrength]: GripStrengthIssueDescription,
  [APITypes.DeviceName.Pox]: POXIssueDescription,
  [APITypes.DeviceName.TissueSystem]: TissueSystemIssueDescription,
  [APITypes.DeviceName.SkinSystem]: SkinSystemIssueDescription,
  [APITypes.DeviceName.Scale]: ScaleIssueDescription,
};

export const createDeviceIssueDescriptionStep = (
  device: APITypes.DeviceName
) => {
  const question = DeviceDescriptionQuestionMap[device];
  if (!question) throw new Error(`No question for device ${device}`);

  return function ({
    answers,
    onAnswer,
    onNext,
    onPrev,
    onResetAnswer,
    isActive,
  }: StepProps) {
    const answer = answers.get(question);
    const response = answers.lookup(question);

    return (
      <QuestionView
        question={question}
        answer={answer}
        cta={
          <ActionButton onClick={onNext} disabled={!response}>
            Continue
          </ActionButton>
        }
        onNext={onNext}
        onPrev={() => {
          onPrev();
          onResetAnswer(question);
        }}
        verticalCentered
        navMode="end-of-content"
      >
        <TextInput
          placeholder="Describe the issue"
          answer={answer}
          onAnswer={(answer) => {
            onAnswer(question, answer);
          }}
          autoFocus={isActive}
        />
      </QuestionView>
    );
  };
};
