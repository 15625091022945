import { ConciergeSessionContext } from "render/context/ConciergeContext";
import { StepFlow } from "./StepFlow";
import { PersistLayer } from "render/layers/PersistLayer";
import { useMemo } from "react";
import { LocalStoragePersister } from "lib/persist/local-storage";
import { useAPIClient } from "render/context/APIContext";

export function Entrypoint() {
  const api = useAPIClient();

  const persister = useMemo(() => {
    return new LocalStoragePersister(api);
  }, [api]);

  return (
    <ConciergeSessionContext>
      <PersistLayer persister={persister}>
        <StepFlow />
      </PersistLayer>
    </ConciergeSessionContext>
  );
}
