import React from "react";
import { HeaderLeftSideContent } from "../HeaderLeftSideContent";
import { HeaderRightSideContent } from "../HeaderRightSideContent";
import { LogoHeader } from "../LogoHeader";
import { PageFrame } from "../PageFrame";

interface FlowFrameProps {
  children: React.ReactNode;
}

export function FlowFrame({ children }: FlowFrameProps) {
  return (
    <PageFrame
      head={
        <LogoHeader
          leftElement={<HeaderLeftSideContent />}
          rightElement={<HeaderRightSideContent />}
        />
      }
    >
      {children}
    </PageFrame>
  );
}
