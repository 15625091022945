import { APITypesV2 } from "@cur8/api-client";
import { useLegalDocumentQuery } from "hooks/queries/useLegalDocumentQuery";
import { useLatestConsentsQuery } from "hooks/queries/usePatientConsentsQuery";
import { parseDocument } from "lib/legal/parseDocument";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { ConsentItem } from "render/views/ChecklistView/components/ConsentItem";
import { BailView } from "render/views/BailView";
import styles from "./styles.module.sass";
import { useIsLatestQuestionnaireCompleteQuery } from "hooks/queries/useQuestionnaireQuery";

const POLL_INTERVAL = 5000;

interface ChecklistViewProps {
  goTo(url: string): void;
  onBack(): void;
}

function SignatureConsentItem({
  consent,
  signature,
}: {
  consent: APITypesV2.ConsentMetadata;
  signature: APITypesV2.ConsentSummary | undefined;
}) {
  const { data: legalDoc } = useLegalDocumentQuery(
    consent.documents.find((document) => document.language === "en")?.uri
  );

  if (!legalDoc) return;

  const { title } = parseDocument(legalDoc);
  const { done, label } = getStatus(signature);

  return <ConsentItem title={title || ""} status={done} statusLabel={label} />;
}

function getStatus(signature: APITypesV2.ConsentSummary | undefined) {
  const { expired, optedOut, revoked } = signature || {};

  if (optedOut)
    return {
      done: true,
      label: "Opted out",
    };

  if (signature && !expired && !revoked)
    return {
      done: true,
      label: "Signed",
    };

  return {
    done: false,
    label: "Not Signed",
  };
}

export function ChecklistView({ goTo, onBack }: ChecklistViewProps) {
  const {
    state: { patient, visit },
  } = useConciergeSessionContext();
  const [canProceed, setCanProceed] = useState(false);
  const [pollConsents, setPollConsents] = useState(true);
  const [pollQuestionnaire, setPollQuestionnaire] = useState(true);

  const isVisitInSweden = visit?.siteId.startsWith("SE-");

  const handleContinue = useCallback(() => {
    const url = isVisitInSweden
      ? paths.medicalJournalConsent.url({})
      : paths.preferredName.url({});
    goTo(url);
  }, [goTo, isVisitInSweden]);

  const { data: polledConsents } = useLatestConsentsQuery({
    patientId: patient?.patientId,
    options: {
      enabled: pollConsents,
      refetchInterval: POLL_INTERVAL,
    },
  });

  const { data: hasCompletedLatestQuestionnaire } =
    useIsLatestQuestionnaireCompleteQuery({
      patientId: patient?.patientId,
      options: {
        enabled: pollQuestionnaire,
        refetchInterval: POLL_INTERVAL,
      },
    });

  const signatures = useMemo(
    () =>
      new Map(
        polledConsents
          ?.filter((c) => !c.relevantSignature.expired)
          .map((c) => [c.consentMetadata.consentId, c.relevantSignature])
      ),
    [polledConsents]
  );

  const duringVisitConsents = visit?.consents
    ?.filter(
      (c) =>
        c.signatureType === APITypesV2.ConsentSignatureType.Self &&
        c.displayStages.includes(APITypesV2.ConsentDisplayStage.DuringVisit)
    )
    .sort((a) =>
      // studies first
      a.consentType === APITypesV2.ConsentType.Study ? -1 : 1
    );

  useEffect(() => {
    if (!polledConsents || !hasCompletedLatestQuestionnaire) return;

    const completedAllConsents = (duringVisitConsents || []).every(
      (c) => getStatus(signatures.get(c.consentId)).done
    );

    setPollConsents(!completedAllConsents);
    setPollQuestionnaire(!hasCompletedLatestQuestionnaire);
    setCanProceed(completedAllConsents && hasCompletedLatestQuestionnaire);
  }, [
    duringVisitConsents,
    signatures,
    polledConsents,
    hasCompletedLatestQuestionnaire,
  ]);

  if (!patient) {
    return <BailView title="No Member Selected" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">Almost there...</Typography>

            <Typography variant="description">
              Please ask the member to complete the steps on their phone to
              continue.
            </Typography>

            <div className={styles.list}>
              {hasCompletedLatestQuestionnaire != null && (
                <ConsentItem
                  title="Questionnaire"
                  status={hasCompletedLatestQuestionnaire}
                  statusLabel={
                    hasCompletedLatestQuestionnaire ? "Completed" : "Incomplete"
                  }
                />
              )}

              {duringVisitConsents?.map((consent) => (
                <SignatureConsentItem
                  key={consent.consentId}
                  consent={consent}
                  signature={signatures.get(consent.consentId)}
                />
              ))}
            </div>
          </>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
              />
            }
            right={
              <ActionButton
                variant="primary"
                disabled={!canProceed}
                onClick={handleContinue}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
