import { PathRoute, useHistory, useLocation } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { useCallback, useMemo } from "react";
import { FlowFrame } from "render/ui/layout/FlowFrame/FlowFrame";
import { Transition } from "./components/Transition";
import { findStep, steps } from "./steps";

export function StepFlow() {
  const { pathname } = useLocation();
  const { push, go } = useHistory();

  const currentIndex = useMemo(() => {
    const currentStep = findStep(pathname);
    if (currentStep) {
      return steps.indexOf(currentStep);
    }
    return 0;
  }, [pathname]);

  const goTo = useCallback(
    (url: string) => {
      push(url);
    },
    [push]
  );

  const goBack = useCallback(() => {
    go(-1);
  }, [go]);

  return (
    <FlowFrame>
      <ViewStack>
        {steps.map(({ path, component }, stepIndex) => {
          const offset = stepIndex - currentIndex;

          return (
            <Transition offset={offset} key={path.name}>
              <PathRoute path={path}>
                {(match) => {
                  return (
                    <Sticky>
                      {match
                        ? component(match.params as any, {
                            goTo: goTo,
                            onBack: goBack,
                          })
                        : null}
                    </Sticky>
                  );
                }}
              </PathRoute>
            </Transition>
          );
        })}
      </ViewStack>
    </FlowFrame>
  );
}
