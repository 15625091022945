import { RadioChoiceInput } from "render/ui/presentation/Questionnaire/components/RadioChoiceInput/RadioChoiceInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import { HasDeviceIssue } from "render/views/IncidentReportView/questions";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";

export function HasDeviceIssueStep({ answers, onAnswer, onNext }: StepProps) {
  const answer = answers.get(HasDeviceIssue);

  return (
    <QuestionView
      question={HasDeviceIssue}
      answer={answer}
      cta={
        <ActionButton disabled={!answer?.response} onClick={onNext}>
          Continue
        </ActionButton>
      }
      onNext={onNext}
      verticalCentered
      navMode="end-of-content"
    >
      <RadioChoiceInput
        name="has-device-issue"
        question={HasDeviceIssue}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(HasDeviceIssue, answer);
        }}
      />
    </QuestionView>
  );
}
