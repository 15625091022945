import { useCallback } from "react";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";

interface CheckOutViewProps {
  goTo(url: string): void;
}

export function CheckOutView({ goTo }: CheckOutViewProps) {
  const handleContinue = useCallback(async () => {
    const url = paths.incidentReport.url({ activeStep: 0 });
    goTo(url);
  }, [goTo]);

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">Ready to check-out?</Typography>
            <Typography variant="description">
              If all examinations are complete
            </Typography>
          </>
        }
        buttons={
          <ActionsFooter
            right={
              <ActionButton onClick={handleContinue}>Check-out</ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
