import React, { useMemo } from "react";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { Typography } from "render/ui/layout/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";

interface StepViewProps {
  caption: React.ReactNode;
  subCaption?: React.ReactNode;
  children: React.ReactNode;
  cta?: React.ReactNode;
  onPrev?: () => void;
  verticalCentered?: boolean;
  navMode?: "sticky-footer" | "end-of-content";
  extraTopPadding?: boolean;
}

export function StepView({
  cta,
  caption,
  children,
  subCaption,
  onPrev,
  verticalCentered = false,
  navMode = "sticky-footer",
  extraTopPadding = false,
}: StepViewProps) {
  const nav = useMemo(
    () => (
      <div className={styles.nav}>
        <ActionsFooter
          left={
            onPrev ? (
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onPrev}
              />
            ) : null
          }
          right={cta}
        />
      </div>
    ),
    [cta, onPrev]
  );
  return (
    <div className={styles.Step}>
      <div
        className={styles.StepView}
        data-vertical-centered={verticalCentered}
        data-extra-top-padding={extraTopPadding}
      >
        <div className={styles.body}>
          <div className={styles.sticky}>
            <Typography variant="large-title">{caption}</Typography>
            {subCaption && (
              <Typography variant="description" weight="light">
                {subCaption}
              </Typography>
            )}
          </div>

          <div className={styles.content}>
            {children}
            {navMode === "end-of-content" && (
              <div
                className={styles.navEndOfContentContainer}
                data-vertical-centered={verticalCentered}
              >
                {nav}
              </div>
            )}
          </div>
        </div>
      </div>
      {navMode === "sticky-footer" && nav}
    </div>
  );
}
