import React from "react";
import styles from "./styles.module.sass";

interface PageFrameContentProps {
  children: React.ReactNode;
}

export function PageFrameContent({ children }: PageFrameContentProps) {
  return <div className={styles.PageFrameContent}>{children}</div>;
}
