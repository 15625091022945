import styles from "./styles.module.sass";
import PointCloudImageWide from "assets/pointcloud/background-pointcloud-wide.png";
import PointCloudImage from "assets/pointcloud/background-pointcloud.png";
import PointCloudVideoWebm from "assets/pointcloud/background-pointcloud.webm";
import PointCloudVideoMP4 from "assets/pointcloud/background-pointcloud.mp4";
import PointCloudVideoWideWebM from "assets/pointcloud/background-pointcloud-wide.webm";
import PointCloudVideoWideMP4 from "assets/pointcloud/background-pointcloud-wide.mp4";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type ScreenVariant = "desktop" | "mobile";

export function VideoBackground() {
  const [opacity, setOpacity] = useState(0);
  const didRender = useRef(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const getVariant = useCallback(() => {
    return window.innerWidth > 980 ? "desktop" : "mobile";
  }, []);
  const [variant, setVariant] = useState<ScreenVariant>(getVariant());

  useEffect(() => {
    const callback = () => {
      setVariant(getVariant());
    };
    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [setVariant, getVariant]);

  useEffect(() => {
    if (!didRender.current) {
      didRender.current = true;
      return;
    }
    if (!videoRef.current) {
      return;
    }

    videoRef.current.pause();
    videoRef.current.load();
    videoRef.current.play();
  }, [variant]);

  const sources = useMemo(() => {
    switch (variant) {
      case "mobile":
        return {
          poster: PointCloudImage,
          mp4: PointCloudVideoMP4,
          webm: PointCloudVideoWebm,
        };
      case "desktop":
        return {
          poster: PointCloudImageWide,
          mp4: PointCloudVideoWideMP4,
          webm: PointCloudVideoWideWebM,
        };
    }
  }, [variant]);

  return (
    <>
      <img
        className={styles.videoBackgroundImage}
        src={sources.poster}
        alt="background"
      />
      <video
        style={{
          opacity,
        }}
        onPlay={() => {
          setOpacity(1);
        }}
        poster={sources.poster}
        ref={videoRef}
        className={styles.videoBackground}
        preload="auto"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={sources.webm} type="video/webm" />
        <source src={sources.mp4} type="video/mp4" />
      </video>
    </>
  );
}
