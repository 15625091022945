export function parseDocument(document: string) {
  const parser = new DOMParser();
  const documentHtml = parser.parseFromString(document, "text/html");
  const title = documentHtml.head.querySelector("title")?.textContent;
  const description = documentHtml.head
    .querySelector('meta[name="description"]')
    ?.getAttribute("content");
  const summary = documentHtml.getElementById("summary");
  const scrollable = documentHtml.getElementById("scrollable");

  return {
    title,
    description,
    summary,
    scrollable,
  };
}
