import { APITypes } from "@cur8/api-client";
import { fromAPI, ImmutableScan, Visit } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { Poll } from "render/hooks/effects";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography/Typography";
import { ChecklistItem } from "render/ui/presentation/ChecklistItem";
import { List } from "render/ui/presentation/List";
import { BailView } from "render/views/BailView/BailView";

function useWaitForScan(type: string, visit?: Visit) {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const [scan, setScan] = useState<ImmutableScan>();

  useEffect(() => {
    if (!visit?.checkinStartTimestamp) {
      return;
    }

    const patientId = visit.patientId;
    const startTime = visit.checkinStartTimestamp;

    async function queryScans(type: string) {
      const scans = await api.scan
        .queryImmutableScans({
          patientId,
          scanType: type,
          order: APITypes.SortOrder.Desc,
          pageSize: 1,
        })
        .result.then((result) => result.items.map(fromAPI.toImmutableScan));

      return scans.find((scan) => {
        return scan.timestamp > startTime;
      });
    }

    return Poll.run(async () => {
      const scan = await queryScans(type).catch(handleError);
      if (scan) {
        setScan(scan);
        return Poll.STOP;
      }
    }, 5000);
  }, [api, type, visit, handleError]);

  return scan;
}

interface CheckInCompleteViewProps {
  goTo(url: string): void;
}

export function CheckInCompleteView({ goTo }: CheckInCompleteViewProps) {
  const {
    state: { patient, visit, room },
  } = useConciergeSessionContext();

  const skinScan = useWaitForScan("skin", visit);
  const cardioScan = useWaitForScan("cardio", visit);
  const tissueScan = useWaitForScan("tci", visit);

  const goToNext = useCallback(() => {
    const url = paths.checkOut.url({});
    goTo(url);
  }, [goTo]);

  useEffect(() => {
    if (!skinScan || !cardioScan || !tissueScan) {
      return;
    }
    goToNext();
  }, [skinScan, cardioScan, tissueScan, goToNext]);

  if (!patient) {
    return <BailView title="No Member" />;
  }

  if (!visit) {
    return <BailView title="No Visit object" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">All set!</Typography>
            <Typography variant="description">
              {patient.name?.displayName || "Member"} is assigned to room{" "}
              {room?.displayName} and ready for the scan.
            </Typography>
            <List type="unordered">
              <ChecklistItem checked={!!skinScan}>Skin Scan</ChecklistItem>
              <ChecklistItem checked={!!cardioScan}>Cardio Scan</ChecklistItem>
              <ChecklistItem checked={!!tissueScan}>Tissue Scan</ChecklistItem>
            </List>
          </>
        }
      />
    </PageFrameContent>
  );
}
