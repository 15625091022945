import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { fetchActiveConsents } from "lib/api/patient/consent";
import { useReporting } from "render/hooks/useReporting";

export function useConsents() {
  const api = useAPIClient();
  const { logError } = useReporting();

  const fetchConsents = useCallback(
    async (patientId: string) => {
      return await fetchActiveConsents(api, patientId).catch(logError);
    },
    [api, logError]
  );

  const revokeConsents = useCallback(
    (patientId: string, activeConsentIds: Array<string>) => {
      if (!activeConsentIds) {
        return;
      }

      return Promise.all(
        activeConsentIds.map((consentId) =>
          api.consent
            .revokeConsent({
              patientId: patientId,
              consentId: consentId,
            })
            .result.catch(logError)
        )
      );
    },
    [api.consent, logError]
  );

  const saveConsent = useCallback(
    (patientId: string, documentUri: string, consentSignatureType: string) => {
      return api.consent
        .saveNewConsent(patientId, {
          documentUri,
          consentSignature: {
            $type: consentSignatureType,
          },
        })
        .result.catch(logError);
    },

    [api.consent, logError]
  );

  return { fetchConsents, revokeConsents, saveConsent };
}
