import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import styles from "./styles.module.sass";

interface ActionsFooterProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export function ActionsFooter({
  left,
  right = (
    <ActionButton variant="primary" disabled>
      Continue
    </ActionButton>
  ),
}: ActionsFooterProps) {
  return (
    <div className={styles.ActionsFooter}>
      <div className={styles.ActionsFooterContainer}>{left}</div>
      <div className={styles.ActionsFooterContainer}>{right}</div>
    </div>
  );
}
