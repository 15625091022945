import { RadioChoiceInput } from "render/ui/presentation/Questionnaire/components/RadioChoiceInput/RadioChoiceInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import { HasAdverseEvent } from "render/views/IncidentReportView/questions";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";
import { Trans } from "render/views/IncidentReportView/trans";

export function HasAdverseEventStep({
  answers,
  onAnswer,
  onNext,
  onResetAnswer,
  onPrev,
}: StepProps) {
  const answer = answers.get(HasAdverseEvent);
  const response = answer?.response;

  return (
    <QuestionView
      question={HasAdverseEvent}
      answer={answer}
      cta={
        <ActionButton disabled={response === undefined} onClick={onNext}>
          <Trans.Continue />
        </ActionButton>
      }
      onNext={() => {
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(HasAdverseEvent);
      }}
      verticalCentered
      navMode="end-of-content"
    >
      <RadioChoiceInput
        name="adverse-event"
        question={HasAdverseEvent}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(HasAdverseEvent, answer);
        }}
      />
    </QuestionView>
  );
}
