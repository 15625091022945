import { ReactComponent as CheckIcon } from "render/assets/icons/check/check-16x16.svg";
import { Typography } from "render/ui/layout/Typography/Typography";
import styles from "./styles.module.sass";

interface ConsentItemProps {
  status: boolean;
  statusLabel: string;
  title: string;
}

export function ConsentItem({ status, statusLabel, title }: ConsentItemProps) {
  return (
    <div className={styles.ConsentItem} data-checked={status}>
      <div className={styles.content}>
        <Typography
          component="span"
          variant="paragraph"
          weight={status ? "bold" : "normal"}
        >
          {title}
        </Typography>
      </div>
      <div className={styles.status}>
        <Typography variant="small" weight="bold" component="span">
          {statusLabel}
        </Typography>
        {status && <CheckIcon width={16} height={16} />}
      </div>
    </div>
  );
}
