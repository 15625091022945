import { DateTime } from "luxon";

interface TimestampProps {
  date: DateTime;
}

export function Timestamp({ date }: TimestampProps) {
  return (
    <time dateTime={date.toISO() ?? ""}>
      {date.toFormat("yyyy-LL-dd HH:mm:ss")}
    </time>
  );
}
