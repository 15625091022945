import { APITypesV1 } from "@cur8/api-client";
import {
  CheckinSessionState,
  Patient,
  Room,
  Slot,
  Visit,
} from "@cur8/rich-entity";

export type SessionState = {
  patient?: Patient;
  consents?: APITypesV1.ConsentSignature[];
  room?: Room;
  session?: CheckinSessionState;
  visit?: Visit;
  slot?: Slot;
};

export function createInitialSession(): SessionState {
  return {};
}
