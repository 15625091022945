import { Slot } from "@cur8/rich-entity";
import { Typography } from "render/ui/layout/Typography";

export function SlotLocation({ slot }: { slot: Slot }) {
  const siteName = slot.room?.site?.siteName;
  const street = slot.room?.site?.address.street;

  return (
    <Typography variant="description">
      <b>Location:</b> {siteName}, {street}
    </Typography>
  );
}
