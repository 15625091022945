import { useCallback, useMemo, useState } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { usePatient } from "render/hooks/usePatient";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography/Typography";
import { Input } from "render/ui/presentation/Input/Input";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "../BailView/BailView";
import { Trans } from "./trans";

interface PreferredNameViewProps {
  goTo(url: string): void;
  onBack(): void;
}

export function PreferredNameView({ goTo, onBack }: PreferredNameViewProps) {
  const { updatePreferredName } = usePatient();
  const {
    update,
    state: { patient },
  } = useConciergeSessionContext();

  const currentName = useMemo(() => {
    return patient?.name?.displayName ?? "";
  }, [patient]);

  const [preferredName, setPreferredName] = useState(currentName);

  const handleContinue = useAsyncHandle(
    useCallback(async () => {
      if (!patient) {
        return;
      }

      if (preferredName !== currentName) {
        const updatedPatient = await updatePreferredName(
          patient.patientId,
          preferredName
        );
        update({ patient: updatedPatient });
      }

      const url = paths.immediateRiskAssessment.url({});

      goTo(url);
    }, [goTo, patient, preferredName, currentName, updatePreferredName, update])
  );

  if (!patient) {
    return <BailView title="No Member Selected" />;
  }

  const canContinue = preferredName.trim().length > 0;

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">
              <Trans.Title />
            </Typography>
            <Input
              label="Preferred Name"
              error={
                preferredName.trim().length === 0
                  ? "Please enter member's preferred name"
                  : undefined
              }
            >
              <input
                type="text"
                value={preferredName}
                onChange={(event) => {
                  setPreferredName(event.target.value);
                }}
              />
            </Input>
          </>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
              />
            }
            right={
              <ActionButton
                disabled={!canContinue}
                onClick={handleContinue.run}
                variant="primary"
                busy={handleContinue.busy}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
