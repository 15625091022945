import { useConciergeSessionContext } from "render/context/ConciergeContext";
import React, { useCallback, useEffect, useState } from "react";
import { Persister } from "lib/persist/persist";
import { SessionState } from "lib/session";
import { WakeUpPendingView } from "render/views/WakeUpPendingView";
import { WakeUpOfferView } from "render/views/WakeUpOfferView";
import { useRouter } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { useReporting } from "render/hooks/useReporting";

enum WakeUpState {
  Pending,
  Offered,
  Ready,
}

type PersistState = {
  state: WakeUpState;
  session?: SessionState;
};

interface PersistLayerProps {
  persister: Persister;
  children: React.ReactNode;
}

export function PersistLayer({ persister, children }: PersistLayerProps) {
  const { handleError } = useReporting();

  const [wakeUp, setWakeUp] = useState<PersistState>({
    state: WakeUpState.Pending,
  });

  const { history } = useRouter();

  const {
    state: session,
    set: setSession,
    reset: resetSession,
  } = useConciergeSessionContext();

  const handleWakeUp = useCallback(async () => {
    const session = await persister.wakeup().catch((error) => {
      handleError(error);
      return null;
    });

    if (session) {
      setWakeUp({
        state: WakeUpState.Offered,
        session,
      });
    } else {
      setWakeUp({
        state: WakeUpState.Ready,
      });
    }
  }, [persister, handleError]);

  useEffect(() => {
    handleWakeUp();
  }, [handleWakeUp]);

  useEffect(() => {
    if (wakeUp.state === WakeUpState.Ready) {
      persister.update(session);
    }
  }, [wakeUp, persister, session]);

  if (wakeUp.state === WakeUpState.Pending) {
    return <WakeUpPendingView />;
  }

  if (wakeUp.state === WakeUpState.Offered && wakeUp.session) {
    const session = wakeUp.session;

    return (
      <WakeUpOfferView
        onAccept={() => {
          console.debug("Hydrating session", session);
          setSession(session);
          setWakeUp({ state: WakeUpState.Ready });
        }}
        onDecline={() => {
          resetSession();

          const url = paths.root.url({});
          history.replace(url);

          setWakeUp({ state: WakeUpState.Ready });
        }}
      />
    );
  }

  return children;
}
