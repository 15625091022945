import { VisitSummary } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "render/assets/icons/arrow2.svg";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useVisit } from "render/hooks/useVisit";
import { paths } from "render/routes/paths";
import { DropdownMenuButton } from "render/ui/trigger/DropdownMenuButton";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import { PatientDetails } from "./PatientDetails/PatientDetails";
import styles from "./styles.module.sass";

export const HeaderRightSideContent = () => {
  const {
    state: { patient },
  } = useConciergeSessionContext();
  const { fetchVisits } = useVisit();
  const [visits, setVisits] = useState<VisitSummary[] | undefined>();
  const name = patient?.name?.displayName;

  useEffect(() => {
    if (patient?.patientId) {
      fetchVisits(patient.patientId).then(setVisits);
    } else {
      setVisits(undefined);
    }
  }, [fetchVisits, patient?.patientId]);

  const nav = {
    incidentReport: useNav(paths.incidentReport),
  };

  const handleAbortCheckin = useAsyncHandle(
    useCallback(async () => {
      nav.incidentReport.go({ activeStep: 0 });
    }, [nav.incidentReport])
  );

  return (
    <div className={styles.HeaderRightSideContent}>
      {patient && (
        <>
          <PatientDetails name={name} visits={visits} />
          <DropdownMenuButton>
            <DropdownItem icon={<ArrowIcon />} onClick={handleAbortCheckin.run}>
              Check out
            </DropdownItem>
          </DropdownMenuButton>
        </>
      )}
    </div>
  );
};
