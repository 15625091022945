import React from "react";
import { Typography } from "render/ui/layout/Typography/Typography";
import { ReactComponent as CheckIcon } from "render/assets/icons/check/check-24x24.svg";
import styles from "./styles.module.sass";

interface ChecklistItemProps {
  checked?: boolean;
  children: React.ReactNode;
}

export function ChecklistItem({ checked, children }: ChecklistItemProps) {
  return (
    <div className={styles.ChecklistItem} data-checked={checked}>
      <div className={styles.icon}>
        <CheckIcon width={18} height={18} />
      </div>
      <div className={styles.content}>
        <Typography component="span" variant="paragraph">
          {children}
        </Typography>
      </div>
    </div>
  );
}
