import { useMemo } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useConfig } from "render/context/ConfigContext";
import {
  type AuthMethod,
  createPatientDeviceURL,
  createSessionToken,
} from "../token";

export function useQRCodeURL(authMethod: AuthMethod) {
  const { state } = useConciergeSessionContext();
  const config = useConfig();

  const { session } = state;

  const token = useMemo(() => {
    if (!session) {
      return;
    }

    return createSessionToken(session?.sessionId);
  }, [session]);

  const url = useMemo(() => {
    if (!token) {
      return;
    }
    const url = config.apiBaseUrl.includes("preprod")
      ? "https://patient.preprod.cur8.co/"
      : config.appConfig.patientAppBaseUrl;
    return createPatientDeviceURL(token, url, authMethod);
  }, [token, config, authMethod]);

  return url;
}
