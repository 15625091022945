import { Locale } from "lib/i18n/localization";

export enum QuestionnaireLanguageCode {
  En = "en",
  Sv = "sv",
}

export function toQuestionnaireLocale(locale: Locale) {
  switch (locale) {
    case Locale.enGB:
      return QuestionnaireLanguageCode.En;
    case Locale.svSE:
      return QuestionnaireLanguageCode.Sv;
  }
}
