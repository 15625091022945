import React from "react";
import LogoIcon from "./assets/logo.svg";
import styles from "./styles.module.sass";

interface LogoHeaderProps {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export function LogoHeader({ leftElement, rightElement }: LogoHeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.left}>
        {leftElement}
        <img src={LogoIcon} className={styles.center} />
      </div>
      <div className={styles.right}>{rightElement}</div>
    </div>
  );
}
