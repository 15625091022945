import { fromAPI, Visit } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useVisit() {
  const api = useAPIClient();

  const { logError } = useReporting();

  const checkIn = useCallback(
    async ({ visitId, patientId }: Pick<Visit, "patientId" | "visitId">) => {
      return api.visit
        .startCheckInProcess({ patientId, visitId })
        .result.then(fromAPI.toVisit)
        .catch(logError);
    },
    [api, logError]
  );

  const fetchVisits = useCallback(
    (patientId: string) => {
      return api.visitSummary
        .querySummary({ patientId }, {})
        .result.then((result) => result.items.map(fromAPI.toVisitSummary))
        .catch(logError);
    },
    [api, logError]
  );

  return { checkIn, fetchVisits };
}
