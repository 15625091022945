import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string | undefined) => [
  "patient-consents",
  patientId,
];
const queryFn =
  (apiClient: APIClient, patientId: string | undefined) => async () => {
    if (patientId == null) {
      return Promise.reject("No patientId");
    }
    return apiClient.consentV2.getListOfConsents({
      patientId,
    }).result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

function usePatientConsentsQuery<T = Body>({
  patientId,
  options = {},
}: {
  patientId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId),
    enabled:
      options.enabled != null
        ? Boolean(patientId) && options.enabled
        : Boolean(patientId),
  });
}

usePatientConsentsQuery.queryKey = queryKey;
usePatientConsentsQuery.queryFn = queryFn;

export function useLatestConsentsQuery<T = Body>({
  patientId,
  options,
}: {
  patientId: string | undefined;
  options?: Omit<QueryOptions<Body, Key, T>, "select">;
}) {
  return usePatientConsentsQuery({
    patientId,
    options: {
      ...options,
      select: (data) => {
        return data.items;
      },
    },
  });
}
