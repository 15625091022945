import React from "react";
import styles from "./styles.module.sass";

interface InputProps {
  label: React.ReactNode;
  error?: React.ReactNode;
  children: React.ReactElement;
}

export function Input({ label, error, children }: InputProps) {
  return (
    <div className={styles.inputContainer} data-error={!!error}>
      {error && <div className={styles.error}>{error}</div>}
      {children}
      <label className={styles.labelTop}>{label}</label>
      <label className={styles.label}>{label}</label>
    </div>
  );
}
