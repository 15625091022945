import { Visit } from "@cur8/rich-entity";
import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";

export function useFinishCheckInMutation() {
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn({ patientId, visitId }: Visit) {
      return apiClient.visit.endCheckInProcess({ patientId, visitId }).result;
    },
  });
}
