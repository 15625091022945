import styles from "./styles.module.sass";

interface GroupProps {
  children: React.ReactNode;
  highlight?: GroupHighlight;
}

interface GetGroupHighlightProps {
  value: boolean | undefined;
  hasError?: boolean;
}
export function getGroupHighlight({
  value,
  hasError,
}: GetGroupHighlightProps): GroupHighlight {
  if (hasError) {
    return "red";
  }

  if (value === undefined) {
    return "none";
  }

  return value ? "blue" : "grey";
}

type GroupHighlight = "none" | "red" | "grey" | "blue";

export function Group({ highlight, children }: GroupProps) {
  return (
    <div data-highlight={highlight} className={styles.Group}>
      {children}
    </div>
  );
}
