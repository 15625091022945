import { UserURI } from "@cur8/uri";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useUserProfilePicture(uri: UserURI, profilePhotoSize: number) {
  const { handleError } = useReporting();

  const api = useAPIClient().user;
  const userUri = uri.toString();
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!userUri) {
      return;
    }

    api
      .getUserProfilePhoto({
        userUri,
        size: profilePhotoSize,
      })
      .result.then((result) => result.blob())
      .then((blob) => {
        setUrl(URL.createObjectURL(blob));
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          handleError(error);
        }
      });
  }, [api, handleError, profilePhotoSize, userUri]);

  return url;
}
