import { Slot } from "@cur8/rich-entity";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { SlotLocation } from "render/views/SessionJoinCompleteView/components/SlotLocation";

interface ConfirmedViewProps {
  slot: Slot;
  onContinue(): Promise<unknown>;
  onBack(): void;
}

export function ConfirmedView({
  slot,
  onContinue,
  onBack,
}: ConfirmedViewProps) {
  const handle = useAsyncHandle(onContinue);

  const slotStart = slot.startTime;
  const formattedSlotStart = slotStart.toFormat("HH:mm");

  return (
    <PageFrameContent>
      <InformationLayout
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
              />
            }
            right={
              <ActionButton
                variant="primary"
                busy={handle.busy}
                onClick={handle.run}
              >
                Continue
              </ActionButton>
            }
          />
        }
        content={
          <>
            <Typography variant="large-title">Proceed to check-in?</Typography>
            <Typography variant="description">
              Member's appointment is scheduled for {formattedSlotStart}.
            </Typography>
            <SlotLocation slot={slot} />
          </>
        }
      />
    </PageFrameContent>
  );
}
