import React from "react";
import styles from "./styles.module.sass";

interface InformationLayoutProps {
  content: React.ReactNode;
  buttons?: React.ReactNode;
  align?: "start" | "end";
}

export function InformationLayout({
  content,
  buttons,
  align,
}: InformationLayoutProps) {
  return (
    <div className={styles.InformationLayout} data-align={align}>
      <div className={styles.content}>{content}</div>
      <div className={styles.buttonset}>{buttons}</div>
    </div>
  );
}
