import { RouterContext } from "@pomle/react-router-paths";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { InternationalizationProvider, Locale } from "lib/i18n/localization";
import { APIContext } from "render/context/APIContext";
import { AppInsightsContext } from "render/context/AppInsightsContext";
import { AuthenticationWall } from "render/context/AuthentificationWall";
import { ConfigContext } from "render/context/ConfigContext";
import { MSALContext } from "render/context/MSALContext";
import { NotificationContext } from "render/context/NotificationContext";
import { Entrypoint } from "render/routes/Entrypoint";
import { NotificationFrame } from "render/ui/layout/NotificationFrame/NotificationFrame";
import { VideoUIFrame } from "render/ui/layout/VideoUIFrame/VideoUIFrame";

const queryClient = new QueryClient();

export function App() {
  return (
    <ConfigContext>
      <AppInsightsContext>
        <NotificationContext>
          <MSALContext>
            <QueryClientProvider client={queryClient}>
              <InternationalizationProvider locale={Locale.enGB}>
                <VideoUIFrame>
                  <AuthenticationWall>
                    <APIContext>
                      <RouterContext history={window.history}>
                        <NotificationFrame>
                          <Entrypoint />
                          <ReactQueryDevtools initialIsOpen={false} />
                        </NotificationFrame>
                      </RouterContext>
                    </APIContext>
                  </AuthenticationWall>
                </VideoUIFrame>
              </InternationalizationProvider>
            </QueryClientProvider>
          </MSALContext>
        </NotificationContext>
      </AppInsightsContext>
    </ConfigContext>
  );
}
