import { APITypes } from "@cur8/api-client";
import { ChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { RadioChoiceInput } from "render/ui/presentation/Questionnaire/components/RadioChoiceInput/RadioChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput/TextInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import { QuestionGroup } from "render/views/IncidentReportView/components/QuestionGroup/QuestionGroup";
import {
  BloodSystemCategoryOfTheIssue,
  BloodSystemIssueLeadToHealthIssues,
  BloodSystemReasonOfTheIssue,
  BloodSystemWhatWasDone,
  BloodSystemWhatWasDoneOtherDetails,
  CardioLasersCategoryOfTheIssue,
  CardioLasersIssueLeadToHealthIssues,
  CardioLasersReasonOfTheIssue,
  CardioLasersWhatWasDone,
  CardioLasersWhatWasDoneOtherDetails,
  CardioRadarCategoryOfTheIssue,
  CardioRadarIssueLeadToHealthIssues,
  CardioRadarReasonOfTheIssue,
  CardioRadarWhatWasDone,
  CardioRadarWhatWasDoneOtherDetails,
  DermaAppCategoryOfTheIssue,
  DermaAppIssueLeadToHealthIssues,
  DermaAppReasonOfTheIssue,
  DermaAppWhatWasDone,
  DermaAppWhatWasDoneOtherDetails,
  DocUICategoryOfTheIssue,
  DocUIIssueLeadToHealthIssues,
  DocUIReasonOfTheIssue,
  DocUIWhatWasDone,
  DocUIWhatWasDoneOtherDetails,
  ECGCategoryOfTheIssue,
  ECGIssueLeadToHealthIssues,
  ECGReasonOfTheIssue,
  ECGWhatWasDone,
  ECGWhatWasDoneOtherDetails,
  EyePressureCategoryOfTheIssue,
  EyePressureIssueLeadToHealthIssues,
  EyePressureReasonOfTheIssue,
  EyePressureWhatWasDone,
  EyePressureWhatWasDoneOtherDetails,
  GripStrengthCategoryOfTheIssue,
  GripStrengthIssueLeadToHealthIssues,
  GripStrengthReasonOfTheIssue,
  GripStrengthWhatWasDone,
  GripStrengthWhatWasDoneOtherDetails,
  MESICategoryOfTheIssue,
  MESIIssueLeadToHealthIssues,
  MESIReasonOfTheIssue,
  MESIWhatWasDone,
  MESIWhatWasDoneOtherDetails,
  POXCategoryOfTheIssue,
  POXIssueLeadToHealthIssues,
  POXReasonOfTheIssue,
  POXWhatWasDone,
  POXWhatWasDoneOtherDetails,
  ScaleCategoryOfTheIssue,
  ScaleIssueLeadToHealthIssues,
  ScaleReasonOfTheIssue,
  ScaleWhatWasDone,
  ScaleWhatWasDoneOtherDetails,
  SkinSystemCategoryOfTheIssue,
  SkinSystemIssueLeadToHealthIssues,
  SkinSystemReasonOfTheIssue,
  SkinSystemWhatWasDone,
  SkinSystemWhatWasDoneOtherDetails,
  TissueSystemCategoryOfTheIssue,
  TissueSystemIssueLeadToHealthIssues,
  TissueSystemReasonOfTheIssue,
  TissueSystemWhatWasDone,
  TissueSystemWhatWasDoneOtherDetails,
} from "render/views/IncidentReportView/questions";
import { DeviceTitle } from "render/views/IncidentReportView/types";
import styles from "./styles.module.sass";
import { StepView } from "render/views/IncidentReportView/components/StepView";

export const DevicReasonOfTheIssueQuestionMap: Record<
  APITypes.DeviceName,
  ChoiceQuestion<APITypes.PossibleReason, string>
> = {
  [APITypes.DeviceName.CardioLasers]: CardioLasersReasonOfTheIssue,
  [APITypes.DeviceName.CardioRadar]: CardioRadarReasonOfTheIssue,
  [APITypes.DeviceName.CardioSystem]: CardioRadarReasonOfTheIssue,
  [APITypes.DeviceName.Ecg]: ECGReasonOfTheIssue,
  [APITypes.DeviceName.MesiSystem]: MESIReasonOfTheIssue,
  [APITypes.DeviceName.BloodSystem]: BloodSystemReasonOfTheIssue,
  [APITypes.DeviceName.DermaApp]: DermaAppReasonOfTheIssue,
  [APITypes.DeviceName.DocUI]: DocUIReasonOfTheIssue,
  [APITypes.DeviceName.EyePressure]: EyePressureReasonOfTheIssue,
  [APITypes.DeviceName.GripStrength]: GripStrengthReasonOfTheIssue,
  [APITypes.DeviceName.Pox]: POXReasonOfTheIssue,
  [APITypes.DeviceName.TissueSystem]: TissueSystemReasonOfTheIssue,
  [APITypes.DeviceName.SkinSystem]: SkinSystemReasonOfTheIssue,
  [APITypes.DeviceName.Scale]: ScaleReasonOfTheIssue,
};

export const DevicCategoryOfTheIssueQuestionMap: Record<
  APITypes.DeviceName,
  ChoiceQuestion<APITypes.IssueCategory, string>
> = {
  [APITypes.DeviceName.CardioLasers]: CardioLasersCategoryOfTheIssue,
  [APITypes.DeviceName.CardioRadar]: CardioRadarCategoryOfTheIssue,
  [APITypes.DeviceName.CardioSystem]: CardioRadarCategoryOfTheIssue,
  [APITypes.DeviceName.Ecg]: ECGCategoryOfTheIssue,
  [APITypes.DeviceName.MesiSystem]: MESICategoryOfTheIssue,
  [APITypes.DeviceName.BloodSystem]: BloodSystemCategoryOfTheIssue,
  [APITypes.DeviceName.DermaApp]: DermaAppCategoryOfTheIssue,
  [APITypes.DeviceName.DocUI]: DocUICategoryOfTheIssue,
  [APITypes.DeviceName.EyePressure]: EyePressureCategoryOfTheIssue,
  [APITypes.DeviceName.GripStrength]: GripStrengthCategoryOfTheIssue,
  [APITypes.DeviceName.Pox]: POXCategoryOfTheIssue,
  [APITypes.DeviceName.TissueSystem]: TissueSystemCategoryOfTheIssue,
  [APITypes.DeviceName.SkinSystem]: SkinSystemCategoryOfTheIssue,
  [APITypes.DeviceName.Scale]: ScaleCategoryOfTheIssue,
};

export const DevicWhatWasDoneQuestionQuestionMap: Record<
  APITypes.DeviceName,
  ChoiceQuestion<APITypes.IncidentReportAction, string>
> = {
  [APITypes.DeviceName.CardioLasers]: CardioLasersWhatWasDone,
  [APITypes.DeviceName.CardioRadar]: CardioRadarWhatWasDone,
  [APITypes.DeviceName.CardioSystem]: CardioRadarWhatWasDone,
  [APITypes.DeviceName.Ecg]: ECGWhatWasDone,
  [APITypes.DeviceName.MesiSystem]: MESIWhatWasDone,
  [APITypes.DeviceName.BloodSystem]: BloodSystemWhatWasDone,
  [APITypes.DeviceName.DermaApp]: DermaAppWhatWasDone,
  [APITypes.DeviceName.DocUI]: DocUIWhatWasDone,
  [APITypes.DeviceName.EyePressure]: EyePressureWhatWasDone,
  [APITypes.DeviceName.GripStrength]: GripStrengthWhatWasDone,
  [APITypes.DeviceName.Pox]: POXWhatWasDone,
  [APITypes.DeviceName.TissueSystem]: TissueSystemWhatWasDone,
  [APITypes.DeviceName.SkinSystem]: SkinSystemWhatWasDone,
  [APITypes.DeviceName.Scale]: ScaleWhatWasDone,
};

export const DeviceCouldLeadToHealthIssuesQuestionQuestionMap: Record<
  APITypes.DeviceName,
  ChoiceQuestion<boolean, string>
> = {
  [APITypes.DeviceName.CardioLasers]: CardioLasersIssueLeadToHealthIssues,
  [APITypes.DeviceName.CardioRadar]: CardioRadarIssueLeadToHealthIssues,
  [APITypes.DeviceName.CardioSystem]: CardioRadarIssueLeadToHealthIssues,
  [APITypes.DeviceName.Ecg]: ECGIssueLeadToHealthIssues,
  [APITypes.DeviceName.MesiSystem]: MESIIssueLeadToHealthIssues,
  [APITypes.DeviceName.BloodSystem]: BloodSystemIssueLeadToHealthIssues,
  [APITypes.DeviceName.DermaApp]: DermaAppIssueLeadToHealthIssues,
  [APITypes.DeviceName.DocUI]: DocUIIssueLeadToHealthIssues,
  [APITypes.DeviceName.EyePressure]: EyePressureIssueLeadToHealthIssues,
  [APITypes.DeviceName.GripStrength]: GripStrengthIssueLeadToHealthIssues,
  [APITypes.DeviceName.Pox]: POXIssueLeadToHealthIssues,
  [APITypes.DeviceName.TissueSystem]: TissueSystemIssueLeadToHealthIssues,
  [APITypes.DeviceName.SkinSystem]: SkinSystemIssueLeadToHealthIssues,
  [APITypes.DeviceName.Scale]: ScaleIssueLeadToHealthIssues,
};

export const DeviceWhatWasDoneOtherDetailsQuestionMap: Record<
  APITypes.DeviceName,
  TextQuestion<string>
> = {
  [APITypes.DeviceName.CardioLasers]: CardioLasersWhatWasDoneOtherDetails,
  [APITypes.DeviceName.CardioRadar]: CardioRadarWhatWasDoneOtherDetails,
  [APITypes.DeviceName.CardioSystem]: CardioRadarWhatWasDoneOtherDetails,
  [APITypes.DeviceName.Ecg]: ECGWhatWasDoneOtherDetails,
  [APITypes.DeviceName.MesiSystem]: MESIWhatWasDoneOtherDetails,
  [APITypes.DeviceName.BloodSystem]: BloodSystemWhatWasDoneOtherDetails,
  [APITypes.DeviceName.DermaApp]: DermaAppWhatWasDoneOtherDetails,
  [APITypes.DeviceName.DocUI]: DocUIWhatWasDoneOtherDetails,
  [APITypes.DeviceName.EyePressure]: EyePressureWhatWasDoneOtherDetails,
  [APITypes.DeviceName.GripStrength]: GripStrengthWhatWasDoneOtherDetails,
  [APITypes.DeviceName.Pox]: POXWhatWasDoneOtherDetails,
  [APITypes.DeviceName.TissueSystem]: TissueSystemWhatWasDoneOtherDetails,
  [APITypes.DeviceName.SkinSystem]: SkinSystemWhatWasDoneOtherDetails,
  [APITypes.DeviceName.Scale]: ScaleWhatWasDoneOtherDetails,
};

export const createDeviceIssueDetailsStep = (device: APITypes.DeviceName) => {
  const questionReasonOfTheIssue = DevicReasonOfTheIssueQuestionMap[device];
  const questionCategoryOfTheIssue = DevicCategoryOfTheIssueQuestionMap[device];
  const questionWhatWasDone = DevicWhatWasDoneQuestionQuestionMap[device];
  const questionWhatWasDoneOtherDetails =
    DeviceWhatWasDoneOtherDetailsQuestionMap[device];
  const questionIssueLeadToHealthIssues =
    DeviceCouldLeadToHealthIssuesQuestionQuestionMap[device];

  return function ({
    answers,
    onAnswer,
    onNext,
    onPrev,
    onResetAnswer,
  }: StepProps) {
    const answerReason = answers.get(questionReasonOfTheIssue);
    const answerCategory = answers.get(questionCategoryOfTheIssue);
    const answerWhatWasDone = answers.get(questionWhatWasDone);
    const answerWhatWasDoneOtherDetails = answers.get(
      questionWhatWasDoneOtherDetails
    );
    const responseWhatWasDone = answers.lookup(questionWhatWasDone);
    const answerIssueLeadToHealthIssues = answers.get(
      questionIssueLeadToHealthIssues
    );

    const hasAllAnswers =
      answerReason &&
      answerCategory &&
      answerWhatWasDone &&
      answerIssueLeadToHealthIssues &&
      (responseWhatWasDone !== APITypes.IncidentReportAction.Other ||
        (responseWhatWasDone === APITypes.IncidentReportAction.Other &&
          answerWhatWasDoneOtherDetails));

    const deviceTitle = DeviceTitle[device];

    return (
      <StepView
        caption={deviceTitle}
        onPrev={() => {
          onPrev();
          onResetAnswer(questionReasonOfTheIssue);
          onResetAnswer(questionCategoryOfTheIssue);
          onResetAnswer(questionWhatWasDone);
          onResetAnswer(questionWhatWasDoneOtherDetails);
          onResetAnswer(questionIssueLeadToHealthIssues);
        }}
        cta={
          <ActionButton disabled={!hasAllAnswers} onClick={onNext}>
            Continue
          </ActionButton>
        }
        navMode="end-of-content"
        extraTopPadding
      >
        <div className={styles.DeviceIssueDetailsStep}>
          <QuestionGroup question={questionReasonOfTheIssue}>
            <RadioChoiceInput
              name="reason"
              question={questionReasonOfTheIssue}
              answer={answerReason}
              onAnswer={(answer) => {
                onAnswer(questionReasonOfTheIssue, answer);
              }}
            />
          </QuestionGroup>
          <QuestionGroup question={questionCategoryOfTheIssue}>
            <RadioChoiceInput
              name="category"
              question={questionCategoryOfTheIssue}
              answer={answerCategory}
              onAnswer={(answer) => {
                onAnswer(questionCategoryOfTheIssue, answer);
              }}
            />
          </QuestionGroup>
          <QuestionGroup question={questionWhatWasDone}>
            <RadioChoiceInput
              name="category"
              question={questionWhatWasDone}
              answer={answerWhatWasDone}
              onAnswer={(answer) => {
                onResetAnswer(questionWhatWasDoneOtherDetails);
                onAnswer(questionWhatWasDone, answer);
              }}
            />
            {responseWhatWasDone === APITypes.IncidentReportAction.Other && (
              <TextInput
                answer={answerWhatWasDoneOtherDetails}
                placeholder="Please specify"
                onAnswer={(answer) => {
                  onAnswer(questionWhatWasDoneOtherDetails, answer);
                }}
              />
            )}
          </QuestionGroup>
          <QuestionGroup question={questionIssueLeadToHealthIssues}>
            <RadioChoiceInput
              name="category"
              question={questionIssueLeadToHealthIssues}
              answer={answerIssueLeadToHealthIssues}
              onAnswer={(answer) => {
                onAnswer(questionIssueLeadToHealthIssues, answer);
              }}
            />
          </QuestionGroup>
        </div>
      </StepView>
    );
  };
};
