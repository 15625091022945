import { Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { SlotStatus } from "lib/api/booking/slot";
import { useCallback } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Typography } from "render/ui/layout/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { SlotLocation } from "render/views/SessionJoinCompleteView/components/SlotLocation";

export interface OutOfRangeViewProps {
  status: SlotStatus;
  slot: Slot;
  onContinue(): Promise<unknown>;
}

export function OutOfRangeView({
  status,
  slot,
  onContinue,
}: OutOfRangeViewProps) {
  const { reset } = useConciergeSessionContext();

  const nav = {
    start: useNav(paths.root),
  };

  const slotStart = slot.startTime;
  const formattedSlotStart = slotStart.toFormat("HH:mm");

  const handleRestart = useCallback(() => {
    nav.start.go({});
    reset();
  }, [nav.start, reset]);

  const handleContinue = useAsyncHandle(onContinue);

  return (
    <PageFrameContent>
      <InformationLayout
        align="start"
        content={
          <>
            {status === SlotStatus.TooEarly && (
              <>
                <Typography variant="large-title">
                  Check in is too early
                </Typography>
                <Typography variant="description">
                  Member's appointment is at {formattedSlotStart}. Please ask
                  the member to come back later.
                </Typography>
              </>
            )}

            {status === SlotStatus.Early && (
              <>
                <Typography variant="large-title">Check in is early</Typography>
                <Typography variant="description">
                  Member's appointment is at {formattedSlotStart}. Would you
                  like to continue the check in anyways?
                </Typography>
              </>
            )}

            {status === SlotStatus.TooLate && (
              <>
                <Typography variant="large-title">
                  Check in is too late
                </Typography>
                <Typography variant="description">
                  Member's appointment was at {formattedSlotStart}. Check in is
                  no longer possible.
                </Typography>
              </>
            )}

            {status === SlotStatus.Late && (
              <>
                <Typography variant="large-title">Check in is late</Typography>
                <Typography variant="description">
                  Member's appointment was at {formattedSlotStart}. Would you
                  like to continue the check in anyways?
                </Typography>
              </>
            )}
            <SlotLocation slot={slot} />
          </>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={handleRestart}
              />
            }
            right={
              <ActionButton
                variant="primary"
                disabled={![SlotStatus.Early, SlotStatus.Late].includes(status)}
                onClick={handleContinue.run}
                busy={handleContinue.busy}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
