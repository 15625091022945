import { UserURI } from "@cur8/uri";
import { useUserProfilePicture } from "render/hooks/useUserProfilePicture";
import styles from "./styles.module.sass";

interface OperatorAvatarProps {
  uri: UserURI;
  size?: "medium" | "large";
}

const AVATAR_SIZE = {
  medium: 32,
  large: 44,
};

const API_IMAGE_SIZE = 64;

export function OperatorAvatar({ uri, size = "medium" }: OperatorAvatarProps) {
  const avatarSize = AVATAR_SIZE[size];
  const profilePicture = useUserProfilePicture(uri, API_IMAGE_SIZE);

  return (
    <div className={styles.OperatorAvatar}>
      <img
        src={profilePicture}
        alt="Avatar"
        width={avatarSize}
        height={avatarSize}
      />
    </div>
  );
}
