import { classNames } from "@pomle/classnames";
import React from "react";
import styles from "./styles.module.sass";

interface CarouselProps {
  index: number;
  children: React.ReactNode;
}

export function Carousel({ index, children }: CarouselProps) {
  const items = React.Children.toArray(children);
  return (
    <div className={styles.Carousel}>
      {items.map((content, itemIndex) => {
        return (
          <div
            key={itemIndex}
            className={classNames("item", {
              current: index === itemIndex,
              prev: index > itemIndex,
              next: index < itemIndex,
            })}
          >
            {content}
          </div>
        );
      })}
    </div>
  );
}
