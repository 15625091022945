import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Typography } from "render/ui/layout/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { Trans } from "./trans";
import { useCallback, useEffect, useState } from "react";
import { paths } from "render/routes/paths";
import styles from "./styles.module.sass";
import { useConfig } from "render/context/ConfigContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { BinaryRadio } from "render/ui/presentation/BinaryRadio";
import { Group } from "render/ui/presentation/Group";
import { DateTime } from "luxon";
import { documentUrlSubDomain } from "render/views/MedicalJournalConsent/constants";
import { useConsents } from "render/hooks/useConsents";

interface ConsentViewProps {
  goTo(url: string): void;
  onBack(): void;
}

export function MedicalJournalConsentView({ goTo, onBack }: ConsentViewProps) {
  const { fetchConsents, saveConsent, revokeConsents } = useConsents();
  const {
    appConfig: { legalApiUrl },
  } = useConfig();
  const {
    state: { patient, consents },
    update,
  } = useConciergeSessionContext();
  const medicalJournalConsentUri = `${legalApiUrl}${documentUrlSubDomain}`;

  const [hasMedicalJournalConsent, setHasMedicalJournalConsent] = useState<
    boolean | undefined
  >(undefined);

  const [activeConsentIds, setActiveConsentIds] = useState<
    Array<string> | undefined
  >(undefined);

  const [hasConsentFromToday, setHasConsentFromToday] = useState<
    boolean | undefined
  >(undefined);

  const handleContinue = useCallback(() => {
    const url = paths.preferredName.url({});
    goTo(url);
  }, [goTo]);

  useEffect(() => {
    if (consents) {
      return;
    }

    if (!patient) {
      return;
    }

    fetchConsents(patient.patientId).then((result) =>
      update({ consents: result })
    );
  }, [consents, fetchConsents, patient, update]);

  useEffect(() => {
    if (!consents) {
      return;
    }
    const matchingConsents = consents.filter(
      (consent) => consent.documentUri === medicalJournalConsentUri
    );
    const hasConsent = matchingConsents.length > 0;
    const matchingConsentIds = matchingConsents.map((consent) => consent.id);
    setHasMedicalJournalConsent(hasConsent ? true : undefined);
    setActiveConsentIds(matchingConsentIds);
    setHasConsentFromToday(
      matchingConsents.some((consent) =>
        DateTime.fromISO(consent.signedAt).hasSame(DateTime.now(), "day")
      )
    );
  }, [consents, medicalJournalConsentUri]);

  const updateMedicalRecordConsent = useAsyncHandle(
    useCallback(async () => {
      if (!patient) {
        return;
      }

      const { patientId } = patient;

      const shouldSave = hasMedicalJournalConsent && !hasConsentFromToday;
      const shouldRevoke =
        !hasMedicalJournalConsent &&
        activeConsentIds &&
        activeConsentIds.length > 0;

      if (shouldSave) {
        await saveConsent(patientId, medicalJournalConsentUri, "onbehalfof");
      }

      if (shouldRevoke) {
        await revokeConsents(patientId, activeConsentIds);
      }

      if (shouldSave || shouldRevoke) {
        const consents = await fetchConsents(patientId);
        update({ consents });
      }
      handleContinue();
    }, [
      activeConsentIds,
      fetchConsents,
      handleContinue,
      hasConsentFromToday,
      hasMedicalJournalConsent,
      medicalJournalConsentUri,
      patient,
      revokeConsents,
      saveConsent,
      update,
    ])
  );

  if (!patient) {
    return <BailView title="No Member Selected" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="large-title">
              <Trans.Title />
            </Typography>
            <Typography variant="paragraph-sub">
              <Trans.Description />
            </Typography>
            <Typography variant="paragraph">
              <Trans.Expiry />
            </Typography>
            <div className={styles.radio}>
              <Group>
                <BinaryRadio
                  label="Consent"
                  name="Consent"
                  value={hasMedicalJournalConsent}
                  onChange={setHasMedicalJournalConsent}
                />
              </Group>
            </div>
          </>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
              />
            }
            right={
              <div className={styles.buttonWrapper}>
                <ActionButton
                  disabled={hasMedicalJournalConsent === undefined}
                  variant="primary"
                  onClick={updateMedicalRecordConsent.run}
                  busy={updateMedicalRecordConsent.busy}
                >
                  <Trans.Continue />
                </ActionButton>
              </div>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
