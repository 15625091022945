import {
  ChoiceQuestion,
  MultiChoiceQuestion,
  TextQuestion,
} from "@cur8/questionnaire";

import { APITypes } from "@cur8/api-client";
import { DeviceTitle } from "render/views/IncidentReportView/types";

export const HasDeviceIssue = new ChoiceQuestion(
  "Where there any device issues?",
  [
    ["Yes", true],
    ["No", false],
  ]
);

export const HasAdverseEvent = new ChoiceQuestion(
  "Where there any safety incidents?",
  [
    ["Yes", true],
    ["No", false],
  ]
);

export const SeverityGrade = new ChoiceQuestion(
  "How severe was the incident?",
  [
    ["Mild", APITypes.Severity.Mild],
    ["Moderate", APITypes.Severity.Moderate],
    ["Severe", APITypes.Severity.Severe],
    ["Life Threatening", APITypes.Severity.LifeThreatening],
  ]
);

export const RelationshipToDevice = new ChoiceQuestion(
  "Was it related to the device?",
  [
    ["Not related", APITypes.RelatedProbability.NotRelated],
    ["Possibly related", APITypes.RelatedProbability.PossiblyRelated],
    ["Probably related", APITypes.RelatedProbability.ProbablyRelated],
    ["Most likely related", APITypes.RelatedProbability.MostLikelyRelated],
  ]
);
export const RelationshipToProcedure = new ChoiceQuestion(
  "Was it related to the exam  procedure?",
  [
    ["Not related", APITypes.RelatedProbability.NotRelated],
    ["Possibly related", APITypes.RelatedProbability.PossiblyRelated],
    ["Probably related", APITypes.RelatedProbability.ProbablyRelated],
    ["Most likely related", APITypes.RelatedProbability.MostLikelyRelated],
  ]
);

export const MitigationAction = new ChoiceQuestion("What was done?", [
  ["Nothing", APITypes.AdverseEventActionTaken.Nothing],
  ["Scan stopped", APITypes.AdverseEventActionTaken.ScanStopped],
  [
    "Treatment given to treat AE",
    APITypes.AdverseEventActionTaken.TreatmentGivenToAE,
  ],
  [
    "Discontinued from study",
    APITypes.AdverseEventActionTaken.DiscontinuedFromStudy,
  ],
]);

export const WhatWasTheOutcome = new ChoiceQuestion("What was the outcome?", [
  ["Resolved", APITypes.AdverseEventOutcome.Resolved],
  ["Resolved with sequelae", APITypes.AdverseEventOutcome.ResolvedWithSequelae],
  ["Ongoing", APITypes.AdverseEventOutcome.Ongoing],
  ["Death", APITypes.AdverseEventOutcome.Death],
]);

export const DeviceList = new MultiChoiceQuestion(
  "Which devices where there issues with?",
  [
    ["Blood system", APITypes.DeviceName.BloodSystem],
    ["Cardio lasers", APITypes.DeviceName.CardioLasers],
    ["Cardio radar", APITypes.DeviceName.CardioRadar],
    ["Derma app", APITypes.DeviceName.DermaApp],
    ["DocUI", APITypes.DeviceName.DocUI],
    ["ECG", APITypes.DeviceName.Ecg],
    ["Eye pressure", APITypes.DeviceName.EyePressure],
    ["Grip strength", APITypes.DeviceName.GripStrength],
    ["MESI system", APITypes.DeviceName.MesiSystem],
    ["POX", APITypes.DeviceName.Pox],
    ["Scale", APITypes.DeviceName.Scale],
    ["Skin system", APITypes.DeviceName.SkinSystem],
    ["Tissue system", APITypes.DeviceName.TissueSystem],
  ]
);

const createDeviceIssueDescriptionQuestion = (device: APITypes.DeviceName) => {
  const deviceTitle = DeviceTitle[device];
  return new TextQuestion(`What was the issue with the ${deviceTitle}?`);
};

export const CardioLasersIssueDescription =
  createDeviceIssueDescriptionQuestion(APITypes.DeviceName.CardioLasers);
export const CardioRadarIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.CardioRadar
);
export const ECGIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.Ecg
);
export const MESIIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.MesiSystem
);
export const BloodSystemIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.BloodSystem
);
export const DermaAppIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.DermaApp
);
export const DocUIIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.DocUI
);
export const EyePressureIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.EyePressure
);
export const GripStrengthIssueDescription =
  createDeviceIssueDescriptionQuestion(APITypes.DeviceName.GripStrength);
export const POXIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.Pox
);
export const TissueSystemIssueDescription =
  createDeviceIssueDescriptionQuestion(APITypes.DeviceName.TissueSystem);
export const SkinSystemIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.SkinSystem
);
export const ScaleIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypes.DeviceName.Scale
);

const createDeviceReasonQuestion = () =>
  new ChoiceQuestion(`Possible reason for the device issue?`, [
    ["Device malfunction", APITypes.PossibleReason.DeviceMalfunction],
    ["Operational issue or bug", APITypes.PossibleReason.OperationalIssueOrBug],
    ["Poor instructions", APITypes.PossibleReason.PoorInstructions],
  ]);

const createDeviceCategoryQuestion = () =>
  new ChoiceQuestion(`How would you categorize the issue?`, [
    [
      "Poor performance (speed, accuracy)",
      APITypes.IssueCategory.PoorPerformance,
    ],
    ["Unreliability", APITypes.IssueCategory.Unreliability],
    ["Poor quality", APITypes.IssueCategory.PoorQuality],
    ["Poor durability (worn out)", APITypes.IssueCategory.PoorDurability],
    ["Lack of safety", APITypes.IssueCategory.LackOfSafety],
    ["Identity (incorrect device)", APITypes.IssueCategory.Identity],
  ]);

const createDeviceWhatWasDoneQuestion = () =>
  new ChoiceQuestion("What was done?", [
    ["Nothing (exam continued)", APITypes.IncidentReportAction.Nothing],
    ["Skipped (exam discontinued)", APITypes.IncidentReportAction.Skipped],
    ["Other", APITypes.IncidentReportAction.Other],
  ]);

const createDeviceIssueLeadToHealthIssues = () =>
  new ChoiceQuestion("Could the issue have lead to a serious health risk?", [
    ["Yes", true],
    ["No", false],
  ]);

const createWhatWasDoneOtherDetailsQuestion = () => new TextQuestion("Other");

export const CardioLasersReasonOfTheIssue = createDeviceReasonQuestion();
export const CardioLasersCategoryOfTheIssue = createDeviceCategoryQuestion();
export const CardioLasersWhatWasDone = createDeviceWhatWasDoneQuestion();
export const CardioLasersIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const CardioLasersWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const CardioRadarReasonOfTheIssue = createDeviceReasonQuestion();
export const CardioRadarCategoryOfTheIssue = createDeviceCategoryQuestion();
export const CardioRadarWhatWasDone = createDeviceWhatWasDoneQuestion();
export const CardioRadarIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const CardioRadarWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const ECGReasonOfTheIssue = createDeviceReasonQuestion();
export const ECGCategoryOfTheIssue = createDeviceCategoryQuestion();
export const ECGWhatWasDone = createDeviceWhatWasDoneQuestion();
export const ECGIssueLeadToHealthIssues = createDeviceIssueLeadToHealthIssues();
export const ECGWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const MESIReasonOfTheIssue = createDeviceReasonQuestion();
export const MESICategoryOfTheIssue = createDeviceCategoryQuestion();
export const MESIWhatWasDone = createDeviceWhatWasDoneQuestion();
export const MESIIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const MESIWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const BloodSystemReasonOfTheIssue = createDeviceReasonQuestion();
export const BloodSystemCategoryOfTheIssue = createDeviceCategoryQuestion();
export const BloodSystemWhatWasDone = createDeviceWhatWasDoneQuestion();
export const BloodSystemIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const BloodSystemWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const DermaAppReasonOfTheIssue = createDeviceReasonQuestion();
export const DermaAppCategoryOfTheIssue = createDeviceCategoryQuestion();
export const DermaAppWhatWasDone = createDeviceWhatWasDoneQuestion();
export const DermaAppIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const DermaAppWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const DocUIReasonOfTheIssue = createDeviceReasonQuestion();
export const DocUICategoryOfTheIssue = createDeviceCategoryQuestion();
export const DocUIWhatWasDone = createDeviceWhatWasDoneQuestion();
export const DocUIIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const DocUIWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const EyePressureReasonOfTheIssue = createDeviceReasonQuestion();
export const EyePressureCategoryOfTheIssue = createDeviceCategoryQuestion();
export const EyePressureWhatWasDone = createDeviceWhatWasDoneQuestion();
export const EyePressureIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const EyePressureWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const GripStrengthReasonOfTheIssue = createDeviceReasonQuestion();
export const GripStrengthCategoryOfTheIssue = createDeviceCategoryQuestion();
export const GripStrengthWhatWasDone = createDeviceWhatWasDoneQuestion();
export const GripStrengthIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const GripStrengthWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const POXReasonOfTheIssue = createDeviceReasonQuestion();
export const POXCategoryOfTheIssue = createDeviceCategoryQuestion();
export const POXWhatWasDone = createDeviceWhatWasDoneQuestion();
export const POXIssueLeadToHealthIssues = createDeviceIssueLeadToHealthIssues();
export const POXWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const ScaleReasonOfTheIssue = createDeviceReasonQuestion();
export const ScaleCategoryOfTheIssue = createDeviceCategoryQuestion();
export const ScaleWhatWasDone = createDeviceWhatWasDoneQuestion();
export const ScaleIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const ScaleWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const SkinSystemReasonOfTheIssue = createDeviceReasonQuestion();
export const SkinSystemCategoryOfTheIssue = createDeviceCategoryQuestion();
export const SkinSystemWhatWasDone = createDeviceWhatWasDoneQuestion();
export const SkinSystemIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const SkinSystemWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const TissueSystemReasonOfTheIssue = createDeviceReasonQuestion();
export const TissueSystemCategoryOfTheIssue = createDeviceCategoryQuestion();
export const TissueSystemWhatWasDone = createDeviceWhatWasDoneQuestion();
export const TissueSystemIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const TissueSystemWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();
