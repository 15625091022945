import React from "react";
import { VideoBackground } from "./components/VideoBackground";

interface VideoUIFrameProps {
  children: React.ReactNode;
}

export function VideoUIFrame({ children }: VideoUIFrameProps) {
  return (
    <>
      {children}
      <VideoBackground />
    </>
  );
}
