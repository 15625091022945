import { APITypesV2 } from "@cur8/api-client";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

function useCounterSignConsent() {
  const apiClient = useAPIClient();

  return useCallback(
    ({
      patientId,
      consent,
    }: {
      patientId: string;
      consent: APITypesV2.ConsentWithSignature;
    }) => {
      if (consent.relevantSignature?.consentId == null) {
        return Promise.reject("No consentId");
      }

      return apiClient.consent.counterSignConsent({
        patientId: patientId,
        consentId: consent.relevantSignature.id,
      }).result;
    },
    [apiClient.consent]
  );
}

export function useCounterSignConsentMutation() {
  return useMutation({
    mutationFn: useCounterSignConsent(),
  });
}
