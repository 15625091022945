import { useNav } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useMSAL } from "render/context/MSALContext";
import { paths } from "render/routes/paths";
import { OperatorHeaderItem } from "render/ui/layout/HeaderRightSideContent/OperatorHeaderItem/OperatorHeaderItem";
import styles from "./styles.module.sass";

export function HeaderLeftSideContent() {
  const { reset } = useConciergeSessionContext();

  const {
    auth,
    session: { logOut },
  } = useMSAL();

  const nav = {
    start: useNav(paths.root),
  };

  const handleOperatorSignOut = useCallback(() => {
    reset();
    nav.start.go({});
    logOut();
  }, [logOut, nav.start, reset]);

  return (
    <div className={styles.HeaderLeftSideContent}>
      {auth && (
        <OperatorHeaderItem
          auth={auth}
          onSignOutClick={handleOperatorSignOut}
        />
      )}
    </div>
  );
}
