import { createContext, useContext, useMemo } from "react";
import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import { useConfig } from "./ConfigContext";

const Context = createContext<ApplicationInsights | null>(null);

interface AppInsightsContextProps {
  children: React.ReactNode;
}

export function AppInsightsContext({ children }: AppInsightsContextProps) {
  const config = useConfig();

  const instance = useMemo(() => {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsights.connectionString,
        distributedTracingMode: DistributedTracingModes.W3C,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: false,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
      if (envelope.data) {
        envelope.data.appName = "ConciergeUI";
        envelope.data.appVersion = import.meta.env.REACT_APP_VERSION;
        envelope.data.origin = window.location.origin;
        envelope.data.href = window.location.href;
      }
    });

    return appInsights;
  }, [config.appInsights.connectionString]);

  return <Context.Provider value={instance}>{children}</Context.Provider>;
}

export function useAppInsights() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAppInsights without AppInsightsContext");
  }
  return context;
}
